import { PaymentPlans } from '@aaa-ncnu-ie/ez-quote-api-billing';
import { PaymentPlanType } from '@aaa-ncnu-ie/ez-quote-session-types';
import {
  BundlePremiumSummary,
  CartPremiumSummary,
} from '../api/schema/premium.schema';
import { formatCurrency, formatCurrencyToNumber } from './formatter';
import { isAbsent, isPresent } from './functions';
import { Optional } from './types';

export const getMonthlyPlan = (plans: Optional<Array<PaymentPlans>>) =>
  plans?.find((p) => p.paymentPlanType === PaymentPlanType.Monthly);

export const getPayInFullPlan = (plans: Optional<Array<PaymentPlans>>) =>
  plans?.find((p) => p.paymentPlanType === PaymentPlanType.PayInFull);

export const getMortgageePlan = (plans: Optional<Array<PaymentPlans>>) =>
  plans?.find((p) => p.paymentPlanType === PaymentPlanType.Mortgagee);

export const getTotalPremiumAmount = (
  plans: Optional<Array<PaymentPlans>>,
  selectedPlan: Optional<string>,
) => {
  const selectedPayPlan = plans?.find(
    (p) => p.paymentPlanType === selectedPlan,
  );

  const premium = selectedPayPlan?.payPlanPremiumAmount;

  return premium != null ? formatCurrencyToNumber(premium) : undefined;
};

export const mapToCartPremiumSummary = (
  value: Optional<BundlePremiumSummary>,
): Optional<CartPremiumSummary> => {
  if (isAbsent(value)) {
    return undefined;
  }

  const paymentPlans: Array<PaymentPlans> = [
    {
      paymentPlanType: PaymentPlanType.PayInFull,
      downPaymentAmount: value.payInFullAmount ?? '$0',
      numberInstallments: '0',
      installmentAmount: '$0',
      payPlanPremiumAmount: value.payInFullAmount ?? '$0',
      percentDiscount: '0',
      firstInstallmentDueDate: null,
    },
    {
      paymentPlanType: PaymentPlanType.Monthly,
      downPaymentAmount: value.paymentPlan?.downPaymentAmount ?? '$0',
      numberInstallments: value.paymentPlan?.numberOfInstallments ?? '0',
      installmentAmount: value.paymentPlan?.installmentAmount ?? '$0',
      payPlanPremiumAmount: value.paymentPlan?.premiumAmount ?? '$0',
      percentDiscount: '0',
      firstInstallmentDueDate: null,
    },
  ];

  if (isPresent(value.payByMortgageAmount)) {
    paymentPlans.push({
      paymentPlanType: PaymentPlanType.Mortgagee,
      downPaymentAmount: value.payByMortgageAmount,
      numberInstallments: '0',
      installmentAmount: '$0',
      payPlanPremiumAmount: value.payByMortgageAmount,
      percentDiscount: '0',
      firstInstallmentDueDate: null,
    });
  }

  return {
    effectiveDate: value.effectiveDate,
    quoteNumber: value.quoteNumber,
    policyTerm: value.policyTerm,
    paymentPlans: paymentPlans,
  };
};

export const getTotalBundleDueToday = (
  dueToday: string | undefined = '',
  bundleDueToday: string | undefined = '',
) => {
  const numberDueToday = formatCurrencyToNumber(dueToday);
  const numberBundleDueToday = formatCurrencyToNumber(bundleDueToday);
  const numberTotal = numberDueToday + numberBundleDueToday;
  return formatCurrency(numberTotal);
};
