import { useCallback, useContext } from 'react';
import { GetDriversResponseV2 } from '@aaa-ncnu-ie/ez-quote-api-driver';
import { RelationshipToPniCd } from '@aaa-ncnu-ie/ez-quote-session-types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment/moment';
import { ClubContext } from '../../../components/contexts/ClubContext';
import { InsuredContext } from '../../../components/contexts/InsuredContext';
import { DriverDescription } from '../../../components/forms/formTypes';
import { useActionMakeRequest } from '../../makeRequest';
import { GetDriversResponse } from '../../schema/driver.schema';
import { PolicyType } from '../../schema/insured.schema';
import { QueryOptions } from '../types';
import { getQueryOptions } from '../utils';
import { driverKeys } from './driverKeys';

type DriverResponseItem = GetDriversResponse[number];
const mapDriverResponseToDriver = (
  driver: DriverResponseItem,
  isCalifornia: boolean,
): DriverDescription => {
  const isDriverPNI = driver.driver.relationship?.toLowerCase() === 'self';
  const isCaliforniaComplete = !isCalifornia || driver.driver.ageFirstLicensed;
  return {
    source: driver.source,
    selected: driver.selected,
    driverId: driver.driverId,
    firstName: driver.driver.firstName,
    lastName: driver.driver.lastName,
    relationship: driver.driver.relationship as RelationshipToPniCd,
    birthDate: driver.driver.birthDate
      ? moment(driver.driver.birthDate, 'YYYY-MM-DD')
      : null,
    gender: driver.driver.gender,
    maritalStatus: driver.driver.maritalStatus,
    ageFirstLicensed: driver.driver.ageFirstLicensed,
    enrollInUBI: driver.driver.enrollInUBI,
    emailUBIDriver: driver.driver.emailUBIDriver,
    mobilePhoneUBIDriver: driver.driver.mobilePhoneUBIDriver,
    drivingLicense: driver.driver.drivingLicense,
    completed: Boolean(
      isDriverPNI
        ? driver.driver.ageFirstLicensed !== undefined && driver.driver.gender
        : driver.driver.birthDate &&
            driver.driver.relationship &&
            driver.driver.gender &&
            isCaliforniaComplete,
    ),
    removalReason: driver.removalReason,
  };
};

export type GetAllDriversResponse = {
  drivers: Array<DriverDescription>;
  driverRemovalReasonEnabled: boolean;
};

export const useMapToUIDriver = () => {
  const { clubState } = useContext(ClubContext);
  const isCalifornia = clubState.state === 'CA';

  return useCallback(
    (driversResponse: GetDriversResponseV2): GetAllDriversResponse => {
      const drivers = driversResponse.drivers?.map(
        (driver): DriverDescription =>
          mapDriverResponseToDriver(driver, isCalifornia),
      );

      return {
        drivers: drivers ?? [],
        driverRemovalReasonEnabled:
          !!driversResponse.featureFlags?.driverRemovalReasonEnabled,
      };
    },
    [isCalifornia],
  );
};

export const useGetAllDrivers = <T = GetAllDriversResponse>({
  onSuccess,
  options,
}: {
  onSuccess?: ((params: GetAllDriversResponse) => void) | null;
  options?: QueryOptions<GetAllDriversResponse, unknown, T>;
}) => {
  const { actionCall: action } = useActionMakeRequest('getAllDrivers');

  const [insuredContextState] = useContext(InsuredContext);
  const policyType = insuredContextState?.insured?.product;

  const transform = useMapToUIDriver();

  return useQuery({
    queryKey: driverKeys.queries.getAll(),
    queryFn: async () => {
      const driversResponse = await action();
      const response = transform(driversResponse);

      onSuccess?.(response);

      return response;
    },
    ...getQueryOptions({
      ...(options ?? {}),
      enabled:
        (options?.enabled ?? true) &&
        !!insuredContextState?.fetched &&
        policyType === PolicyType.AUTO,
    }),
  });
};

export const useInvalidateGetAllDriversV1Query = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: driverKeys.queries.getAll(),
    });
};
