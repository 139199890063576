import React, { createContext, useMemo, useState } from 'react';
import { ContainerProps } from '@mui/material';

export type ErrorPageContextState = {
  reason: string;
  errorCode: string;
  pasErrorCode?: string;
  rawErrorMessage?: string;
  additionalInfo?: Record<string, any>;

  /**
   * When multiple error codes are returned, will be sending one analytic event per error code
   */
  errorCodes?: string[];
};

export type SetErrorPageContextState = (
  newState: ErrorPageContextState
) => void;

type ErrorPageContext = [ErrorPageContextState, SetErrorPageContextState];

const defaultContextState: ErrorPageContextState = {
  reason: '',
  errorCode: '',
  pasErrorCode: '',
  rawErrorMessage: '',
  errorCodes: undefined,
  additionalInfo: undefined,
};

export const ErrorPageContext = createContext<ErrorPageContext>([
  defaultContextState,
  () => undefined,
]);

const ErrorPageContextProvider: React.FC<ContainerProps> = (props) => {
  const [errorPageState, setErrorPageState] = useState(defaultContextState);

  const contextValue: [
    ErrorPageContextState,
    React.Dispatch<React.SetStateAction<ErrorPageContextState>>
  ] = useMemo(() => [errorPageState, setErrorPageState], [errorPageState]);

  return (
    <ErrorPageContext.Provider value={contextValue}>
      {props.children}
    </ErrorPageContext.Provider>
  );
};

export default ErrorPageContextProvider;
