import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExitReason } from '@aaa-ncnu-ie/ez-quote-session-types';
import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useDeviceTypes } from 'helpers/devices';
import { getPathByName, Tabs } from 'helpers/pages';
import { formatPhoneNumberAlternative } from 'helpers/phone';
import { getAttribute } from 'helpers/testing';
import { useExitQuote } from 'hooks/useExitQuote';
import useSegment from 'hooks/useSegment';
import { useStartOver } from 'hooks/useStartOver';
import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/common/Button';
import TextInput from 'components/common/TextInput';
import { TypedField } from 'components/common/TypedField';
import { InsuredContext } from 'components/contexts/InsuredContext';
import { ModalContext } from 'components/contexts/ModalContext';
import { QuoteContext } from 'components/contexts/QuoteContext';
import { FormValues } from 'components/forms/formTypes';
import { Spacer } from 'components/layouts/Spacer';
import Modal from '../common/Modal';
import { CircularLoader } from '../Loading';

const useSaveQuoteModalStyles = makeStyles()(({ breakpoints }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 32px 64px 32px',
    paddingBottom: 64,
  },
  text: {
    fontFamily: 'Cabin',
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 500,
    color: colors.grey60,
  },
  formSpacing: {
    marginBottom: '32px',
  },
  buttonSpacing: {
    marginTop: '32px',
  },
  selectTitle: {
    [breakpoints.only('mobile')]: {
      display: 'inline-flex',
      maxWidth: '220px',
    },
  },
  saveQuoteButton: {
    [breakpoints.up('desktop')]: {
      margin: '0 35px',
    },
    [breakpoints.down('desktop')]: {
      width: '100%',
      margin: '12px auto',
    },
  },
  saveQuoteContainer: {
    [breakpoints.up('desktop')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      opacity: 1,
    },
  },
}));

const SaveQuoteModalForm = (props: {
  currentTabIndex: Tabs;
  open?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClose?: () => void;
  text?: string;
  hasPassedDiscountSummary?: boolean;
  handleModalClose?: () => void;
}) => {
  const { onClose, text, open, handleModalClose, hasPassedDiscountSummary } =
    props;
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const { isMobile } = useDeviceTypes();
  const { startOver } = useStartOver();
  const { t } = useTranslation();
  const key = 'save-quote';
  const { classes } = useSaveQuoteModalStyles();
  const { track } = useSegment();
  const pageLastViewed = window.location.pathname + window.location.search;
  const history = useHistory();
  const [previousPath, setPreviousPath] = useState<string | undefined>();
  const [insuredContext] = useContext(InsuredContext);
  const { handleModal } = useContext(ModalContext);
  const { quoteElementState, setQuoteElementState } = useContext(QuoteContext);
  const { saveQuoteModalOpen } = quoteElementState;
  const modalOpen = saveQuoteModalOpen || Boolean(open);
  const { exitQuote } = useExitQuote();

  const hasInfoAlready =
    insuredContext.insured?.email && insuredContext.insured?.phoneNumber;

  // NOTE: There is a requirement to persist current screen
  // when user clicks "Save Quote" if they provided data. The easiest way to do this is
  // to click "Continue" for user. However, it takes user to the next screen,
  // and business wants them to stay on the current one. Because of that
  // we are redirecting to the previous screen once that happens.
  useEffect(() => {
    if (previousPath && previousPath !== history.location.pathname) {
      // NOTE: An attempt to hide the fact that we are navigating forward
      // as there is no easy way to overwrite history. Suggestions are welcome
      history.replace(previousPath);

      // NOTE: Same as above, this will keep a lingering forward state,
      // but it will be overwritten once user clicks continue
      history.go(-1);
      setPreviousPath(undefined);
    }
  }, [history.location.pathname, previousPath]);

  useEffect(() => {
    persistCompletedScreen();
    track('Link Accessed', {
      link: 'save my quote button',
      link_placement: 'navigation menu',
      link_destination: 'Save Quote Modal',
      eventType: 'Link Accessed',
    });
  }, []);

  // If user entered data and didn't click continue, we need to click continue for them
  // so that we don't lose their data
  const persistCompletedScreen = () => {
    const button = document.querySelector(
      '.buttonPrimary:not(.disabled-auto-continue)',
    ) as HTMLButtonElement;
    const isEnabled = button?.disabled === false;
    if (isEnabled) {
      setPreviousPath(`${history.location.pathname}${history.location.search}`);
      button.click();
    }
  };

  const handleContinue = async (email: string, phoneNumber: string) => {
    setIsAwaitingResponse(true);
    const analyticProps = {
      eventType: 'User Information Entered',
      email,
      phone_number: phoneNumber,
    };
    track('Contact Information Entered', analyticProps);
    await exitQuote({
      email,
      phoneNumber: phoneNumber.replaceAll(/\D/gi, ''),
      exitReason:
        hasPassedDiscountSummary === undefined
          ? ExitReason.SAVE_QUOTE
          : hasPassedDiscountSummary
            ? ExitReason.PURCHASE_ABANDONED
            : ExitReason.ABANDONED,
      pageLastViewed: previousPath ?? pageLastViewed,
    })
      .then(() => {
        track('Quote Saved', analyticProps);
        saveQuoteModalOpen &&
          setQuoteElementState({
            ...quoteElementState,
            saveQuoteModalOpen: false,
          });
        handleModalClose && handleModalClose();
        history.push(getPathByName('ThankYou'));
        setIsAwaitingResponse(false);
      })
      .catch(() => {
        handleModalClose && handleModalClose();
        handleModal({
          key: 'session-timeout-modal',
          heading: t('sessiontimeoutmodal.title'),
          onCancel: startOver,
          cancelButtonText: t('sessiontimeoutmodal.cancelButtonText'),
        });
      });
  };

  const modalText = text ? text : t('savequotemodal.text');

  const form = useFormikContext<Partial<FormValues>>();
  const { values, errors, setFieldValue } = form;
  const isValid =
    !!values.email &&
    !!values.cellNumber &&
    !(errors.email || errors.cellNumber);

  return (
    <Modal
      fullWidth={isMobile}
      testId={key}
      title={t('savequotemodal.title')}
      open={modalOpen}
      modalName="save-quote-modal"
      onClose={() => {
        onClose
          ? onClose()
          : setQuoteElementState({
              ...quoteElementState,
              saveQuoteModalOpen: false,
            });
      }}
    >
      {isAwaitingResponse && <CircularLoader transparent />}
      {/* Note: helps with performance */}
      <>
        {modalOpen && (
          <form className={classes.form}>
            <Typography className={classes.text}>
              {t(hasInfoAlready ? 'savequotemodal.info' : modalText)}
            </Typography>
            {!hasInfoAlready && (
              <>
                <Spacer space={isMobile ? 24 : 40} />
                <TypedField
                  {...getAttribute(key, `field-email`)}
                  fullWidth
                  component={TextInput}
                  name="email"
                  label={t('savequotemodal.email')}
                  id="email-field"
                />
                <Spacer space={16} orientation="vertical" />
                <TypedField
                  {...getAttribute(key, `field-phone`)}
                  fullWidth
                  component={TextInput}
                  label={t('savequotemodal.phone')}
                  name="cellNumber"
                  id="cellNumber-field"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    const cellNumber = event.target.value;
                    setFieldValue(
                      'cellNumber',
                      formatPhoneNumberAlternative(cellNumber),
                    );
                  }}
                  inputProps={{ maxLength: 14 }}
                />
              </>
            )}

            <Spacer space={isMobile ? 25 : 40} orientation="vertical" />
            <Button
              fullWidth
              color="primary"
              csaaType="primary"
              variant="contained"
              disabled={!isValid}
              onClick={() =>
                handleContinue(values.email || '', values.cellNumber || '')
              }
            >
              {t('buttons.continue')}
            </Button>
          </form>
        )}
      </>
    </Modal>
  );
};

export default SaveQuoteModalForm;
