import { Resource, ResourceLanguage } from 'i18next';
import rawEN_US from './en-US/translation.json';

const en_US: ResourceLanguage = rawEN_US;

const locales: Resource = {
  'en-US': {
    translation: en_US,
  },
};

export default locales;
