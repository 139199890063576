import React from 'react';
import MaterialButton, { ButtonProps } from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import { handleInputActivity } from '../../helpers/formotiv';
import { colors } from '../../styles/theme';

type CSAAButtonType = 'primary' | 'secondary' | 'tertiary';

export interface CSAAButtonProps extends ButtonProps {
  csaaType: CSAAButtonType;
}

const usePrimaryStyles = makeStyles()(() => ({
  root: {
    minHeight: '42px',
    minWidth: '143px',
    display: 'flex',
    backgroundColor: colors.primaryBlue50,
    color: colors.white,
    padding: '8px 32px 8px 32px',
    boxShadow: 'none',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '32px',
    fontFeatureSettings: "'pnum' on, 'lnum' on, 'salt' on",
    textTransform: 'none',
    '&:focus': {
      backgroundColor: colors.primaryBlue70,
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: colors.spectrumBlue60,
    },
    '&:disabled': {
      background: colors.grey30,
      color: colors.grey100,
      fontWeight: 'normal',
    },
    '&[aria-disabled="true"]': {
      background: colors.grey30,
      color: colors.grey100,
      fontWeight: 'normal',
    },
  },
}));

const useSecondaryStyles = makeStyles()(() => ({
  textSecondary: {
    border: 'unset!important',
    background: 'transparent!important',
  },
  root: {
    minHeight: '42px',
    minWidth: '143px',
    backgroundColor: colors.white,
    border: `2px solid ${colors.grey100}`,
    color: colors.grey100,
    padding: '8px 32px 8px 32px',
    boxShadow: 'none',
    boxSizing: 'border-box', // to accommodate the exterior border
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '32px',
    fontFeatureSettings: "'pnum' on, 'lnum' on, 'salt' on",
    textTransform: 'none',
    '&:hover': {
      border: `2px solid ${colors.grey100}`,
      backgroundColor: colors.grey5,
      boxShadow: 'none',
    },
    '&:focus': {
      border: `3px solid ${colors.grey100}`,
      backgroundColor: colors.grey5,
    },
    '&:disabled': {
      background: colors.grey5,
      border: `1px solid ${colors.grey30}`,
      color: colors.grey30,
      fontWeight: 'normal',
    },
  },
}));

const useTertiaryStyles = makeStyles()(() => ({
  root: {
    minHeight: '42px',
    minWidth: '143px',
    color: colors.white,
    backgroundColor: colors.grey100,
    padding: '8px 32px 8px 32px',
    boxShadow: 'none',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '32px',
    fontFeatureSettings: "'pnum' on, 'lnum' on, 'salt' on",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colors.grey90,
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: colors.grey100,
    },
    '&:active': {
      backgroundColor: colors.grey100,
    },
    '&:disabled': {
      background: colors.grey30,
      color: colors.grey60,
      fontWeight: 'normal',
    },
  },
}));

export default ({ onClick, ...props }: CSAAButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }

    try {
      let id = props.id;
      if (!id && props.children && typeof props.children === 'string') {
        const text = e.currentTarget.innerText;
        id = text.replace(/\s/g, '-').toLowerCase();
      }

      handleInputActivity({
        fieldName: props.id || id || 'button',
        type: 'button',
      });
    } catch (e) {
      console.error('[Formotiv] Error in onClick', e);
    }
  };

  switch (props.csaaType) {
    case 'primary':
      return <ButtonPrimary {...props} onClick={handleClick} />;
    case 'secondary':
      return <ButtonSecondary {...props} onClick={handleClick} />;
    case 'tertiary':
      return <ButtonTertiary {...props} onClick={handleClick} />;
    default:
      return <ButtonPrimary {...props} onClick={handleClick} />;
  }
};

export const ButtonPrimary = ({ children, ...props }: CSAAButtonProps) => {
  const { classes } = usePrimaryStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, csaaType, ...restProps } = props;
  return (
    <MaterialButton
      className={`${classes.root} ${className} buttonPrimary`}
      {...restProps}
    >
      {children}
    </MaterialButton>
  );
};

export const ButtonSecondary = ({ children, ...props }: CSAAButtonProps) => {
  const { classes } = useSecondaryStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, csaaType, ...restProps } = props;
  return (
    <MaterialButton
      classes={{
        textSecondary: classes.textSecondary,
      }}
      className={`${classes.root} ${className} buttonSecondary`}
      {...restProps}
    >
      {children}
    </MaterialButton>
  );
};

export const ButtonTertiary = ({ children, ...props }: CSAAButtonProps) => {
  const { classes } = useTertiaryStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, csaaType, ...restProps } = props;
  return (
    <MaterialButton
      className={`${classes.root} ${className} buttonTertiary`}
      {...restProps}
    >
      {children}
    </MaterialButton>
  );
};
