import { DriverMetadataRequest } from '../../schema/driver.schema';
import { getSessionIdKey } from '../utils';

// Ref: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

export const driverKeys = {
  common: () => {
    const sessionIdKey = getSessionIdKey();
    return [sessionIdKey, 'driver'] as const;
  },
  queries: {
    getMetadata: (params: DriverMetadataRequest) =>
      [...driverKeys.common(), 'get', 'metadata', params] as const,
    getAll: () => [...driverKeys.common(), 'get', 'all'] as const,
  },
  mutations: {
    updateSelectedDrivers: () =>
      [...driverKeys.common(), 'updateSelectedDrivers'] as const,
  },
};
