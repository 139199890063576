import React from 'react';

export const Spacer = (props: {
  space: number;
  orientation?: 'vertical' | 'horizontal';
}) => {
  const { space, orientation = 'vertical' } = props;
  const verticalPadding = (orientation === 'vertical' && space) || 0;
  const horizontalPadding = (orientation === 'horizontal' && space) || 0;
  const padding = `${verticalPadding}px ${horizontalPadding}px 0 0`;

  return (
    <div
      style={{
        display: 'flex',
        background: 'transparent',
        // NOTE: Padding spacer allows for better ineraction with browser dev tools
        // Also more semantically correct
        padding,
      }}
    ></div>
  );
};
