import { useCallback, useState } from 'react';

export default function useModal() {
  const [modal, setModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState({});
  const handleModal = useCallback((content: boolean | any = false) => {
    setModal((prevModal) => !prevModal);
    if (content) {
      setModalContent(content);
    }
  }, []);

  return { modal, handleModal, modalContent };
}
