import { PolicyType } from '@aaa-ncnu-ie/ez-quote-session-types';

/**
 * Determines if the current carrier page should be hidden based on the given inputs.
 *
 * @param {boolean | undefined} currentCarrierPrefilled - Indicates if the current carrier is prefilled.
 * @param {PolicyType} policyType - The type of policyType being considered.
 * @returns {boolean} - Indicates if the current carrier page should be hidden.
 */
export const resolveCurrentCarrierPageVisibility = (
  currentCarrierPrefilled: boolean | undefined,
  policyType: PolicyType,
): boolean => {
  if (policyType === PolicyType.CONDO) {
    return true;
  }

  return Boolean(currentCarrierPrefilled);
};
