import {
  DiscountName,
  DriverDiscount,
} from '@aaa-ncnu-ie/ez-quote-api-discounts';
import { AppliedDiscount } from 'api/schema/discounts.schema';

export const sortDiscounts = (discounts: AppliedDiscount[]) => {
  return discounts.sort((a, b) => {
    const nameA = a.discountName.toUpperCase();
    const nameB = b.discountName.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });
};

// Currently, the Bundle Savings amount is equals to the Multi-Policy Discount (MPD) amount.
export const getBundleSavingsAmount = (
  discounts: AppliedDiscount[]
): number => {
  const mpd = discounts.find(
    ({ discountName }) => discountName === 'Multi-Policy'
  );

  return mpd?.discountAmount || 0;
};

export const mapDiscountNameToRoute = (discount: string) => {
  if (discount.includes(DriverDiscount.DefensiveDriver)) {
    return 'DefensiveDriverDiscount';
  } else if (discount.includes(DriverDiscount.GoodStudent)) {
    return 'StudentDiscount';
  } else if (discount.includes(DriverDiscount.SmartDriver)) {
    return 'SmartDriverDiscount';
  } else if (discount.includes(DriverDiscount.Travelink)) {
    return 'TravelinkDiscount';
  } else if (discount.includes(DiscountName.TheftAlarm)) {
    return 'TheftAndFireAlarmDiscount';
  } else if (discount.includes(DiscountName.FireAlarm)) {
    return 'TheftAndFireAlarmDiscount';
  } else if (discount.includes(DiscountName.ResidentialSprinklers)) {
    return 'SprinklerDiscount';
  } else {
    return false;
  }
};
