import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useDeviceTypes } from 'helpers/devices';
import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/common/Button';
import { Spacer } from 'components/layouts/Spacer';
import Modal from '../common/Modal';

const useStyles = makeStyles()(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '480px',
    padding: '16px 32px 64px 32px',
    overflow: 'hidden',
  },
  text: {
    marginBottom: '24px',
    fontFamily: 'Cabin',
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 500,
    color: colors.grey60,
  },
  button: {
    height: '42px',
  },
}));

const SessionInactiveModal = (props: {
  open: boolean;
  onClose: () => void;
  onAffirmative: () => void;
  onNegative: () => void;
}) => {
  const { open, onClose, onAffirmative, onNegative } = props;
  const { isMobile } = useDeviceTypes();
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      testId={'SessionInactiveModal'}
      fullWidth={isMobile}
      maxWidth={'tablet'}
      title={t('sessionInactive.title')}
      open={open}
      modalName="session-inactive-modal"
      onClose={onClose}
    >
      <div className={classes.form}>
        <Typography className={classes.text}>
          {t('sessionInactive.text')}
        </Typography>
        <>
          <Spacer space={16} />
          <Button
            fullWidth={true}
            color="primary"
            csaaType="primary"
            variant="contained"
            classes={{ root: classes.button }}
            onClick={onAffirmative}
          >
            {t('sessionInactive.buttonAffirmative')}
          </Button>
        </>
        <>
          <Spacer space={16} />
          <Button
            fullWidth={true}
            color="secondary"
            csaaType="secondary"
            variant="contained"
            classes={{ root: classes.button }}
            onClick={onNegative}
          >
            {t('sessionInactive.buttonNegative')}
          </Button>
        </>
      </div>
    </Modal>
  );
};

export default SessionInactiveModal;
