import { QuoteExit } from 'api/schema/quote.schema';
import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import { getCookie, setCookie } from 'helpers/cookies';

type Response = {
  exitQuote: (body: QuoteExit) => Promise<Record<string, unknown> | undefined>;
};
export const useExitQuote = (): Response => {
  const makeRequest = useRequest();
  const {
    actionCreators: { quoteExit },
  } = useActionCreators();
  const hasExited = !!getCookie('hasExited');

  const response: Response = {
    exitQuote: () => Promise.resolve(undefined),
  };

  if (!hasExited)
    response.exitQuote = (body: QuoteExit) =>
      makeRequest(
        () => quoteExit(body),
        () => setCookie('hasExited', true)
      );

  return response;
};
