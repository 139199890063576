import { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export type CSAADeviceTypes = 'Desktop' | 'Mobile' | 'Tablet' | '';

export type DeviceType = {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  segmentDeviceType: CSAADeviceTypes;
};

export function useDeviceTypes() {
  const { breakpoints } = useTheme();

  // the noSsr: true is there because there are two passes to facilitate apps that use SSR
  // so on the first render, these all come back as false, which leads to issues when you need
  // execution on first mount and aren't listening for re-renders
  // with the noSsr option, these are populated as expected the first time
  // see: https://github.com/mui-org/material-ui/issues/14336
  const isDesktop = useMediaQuery(breakpoints.up('desktop'), { noSsr: true });
  const isTablet = useMediaQuery(breakpoints.only('tablet'), { noSsr: true });
  const isMobile = useMediaQuery(breakpoints.only('mobile'), { noSsr: true });

  const segmentDeviceType: CSAADeviceTypes = useMemo(() => {
    if (isTablet) {
      return 'Tablet';
    } else if (isDesktop) {
      return 'Desktop';
    } else {
      return 'Mobile';
    }
  }, [isMobile, isTablet, isDesktop]);

  return {
    isDesktop,
    isTablet,
    isMobile,
    segmentDeviceType,
  };
}
