import { useContext } from 'react';
import { MetadataResponse as DriverMetadataResponse } from '@aaa-ncnu-ie/ez-quote-api-driver';
import { useQuery } from '@tanstack/react-query';
import { ClubContext } from '../../../components/contexts/ClubContext';
import { InsuredContext } from '../../../components/contexts/InsuredContext';
import { hasText } from '../../../helpers/functions';
import { useActionMakeRequest } from '../../makeRequest';
import { DriverMetadataRequest } from '../../schema/driver.schema';
import { PolicyType } from '../../schema/insured.schema';
import { QueryOptions } from '../types';
import { getQueryOptions } from '../utils';
import { driverKeys } from './driverKeys';

export const useGetDriverMetadata = <T = DriverMetadataResponse>(
  options?: QueryOptions<DriverMetadataResponse, unknown, T>,
) => {
  const { actionCall: action } = useActionMakeRequest('getDriverMetadata');

  const [insuredContextState] = useContext(InsuredContext);
  const { clubState } = useContext(ClubContext);

  const params = {
    state: clubState?.state,
    policyType: insuredContextState?.insured?.product,
  } satisfies DriverMetadataRequest;

  return useQuery({
    queryKey: driverKeys.queries.getMetadata(params),
    queryFn: () => action(params),
    staleTime: 1000 * 60 * 10, // 10 minutes
    ...getQueryOptions({
      ...(options ?? {}),
      enabled:
        (options?.enabled ?? true) &&
        !!insuredContextState?.fetched &&
        hasText(params.state) &&
        params.policyType != null &&
        params.policyType === PolicyType.AUTO,
    }),
  });
};
