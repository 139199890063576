import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import { PolicyType } from 'api/schema/insured.schema';
import { ClubContext } from 'components/contexts/ClubContext';
import { FormValues } from 'components/forms/formTypes';
import { useFormikContext } from 'formik';
import { getNameByPath } from 'helpers/pages';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useCountyTownship = () => {
  const { values } = useFormikContext<FormValues>();
  const { clubState } = useContext(ClubContext);
  const location = useLocation();
  const isPreviousAddressPage =
    getNameByPath(location.pathname) === 'PreviousAddress';
  const pageType = isPreviousAddressPage ? 'previous' : 'current';
  const bypassAddress = values?.bypassAddress?.[pageType];
  const isAuto = values.product === PolicyType.AUTO;
  const isConnecticut =
    [clubState?.state, bypassAddress?.state].includes('CT') && isAuto;
  const postalCode = bypassAddress?.zip || values.addressZip;
  const makeRequest = useRequest();
  const {
    actionCreators: { getCountyTownship },
  } = useActionCreators();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (isAuto && isConnecticut && postalCode && postalCode.length === 5) {
      setFieldValue(`bypassAddress.${pageType}.countyOptions`, undefined);
      makeRequest(() =>
        getCountyTownship({
          postalCode,
        })
      ).then((res) => {
        if (res) {
          const entries = Object.entries(res);
          const isPopulated = entries.find(
            ([_township]) => values.township === _township
          );
          const hasOnlyOne = entries.length === 1;
          setFieldValue(`bypassAddress.${pageType}.countyOptions`, entries);
          setFieldValue(
            `bypassAddress.${pageType}.county`,
            (isPopulated && isPopulated[1]) ||
              (hasOnlyOne && entries[0]?.[1]) ||
              ''
          );
        }
      });
    }
  }, [postalCode, isConnecticut]);
};
