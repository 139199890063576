const stringifyIfNotPrimitive = (value: any): string => {
  if (value === null || value === undefined) {
    return String(value);
  }

  const type = typeof value;
  const isPrimitive =
    type === 'string' || type === 'number' || type === 'boolean';

  return isPrimitive ? String(value) : JSON.stringify(value);
};

interface HandleInputActivityParams {
  fieldName: string;
  type:
    | 'select'
    | 'button'
    | 'checkbox'
    | 'date'
    | 'switch'
    | 'radio'
    | 'number';
  value?: any;
  input?: HTMLElement;
}

/**
 * Handles the activity for a specific field.
 *
 * @param fieldName
 * @param type
 * @param value
 * @param input
 * @returns {void}
 */
export const handleInputActivity = ({
  fieldName,
  type,
  value,
  input,
}: HandleInputActivityParams): void => {
  try {
    if (typeof formotiv !== 'undefined') {
      const config = formotiv.FormotivDefaultConfig.Instance();
      const stringifyValue = stringifyIfNotPrimitive(value);

      formotiv.inputActivity(
        config,
        fieldName,
        type,
        stringifyValue,
        undefined,
        input,
      );

      if (config.loglevel === formotiv.LogLevel.Debug) {
        console.debug('::: [Formotiv] handleForMotivInputActivity', {
          fieldName,
          type,
          value: stringifyValue,
        });
      }
    } else {
      console.debug('::: [Formotiv] is not defined');
    }
  } catch (e) {
    console.error('[Formotiv] Error in handleForMotivInputActivity', e);
  }
};
