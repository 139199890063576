import { UpdateDriverSelectionRequestV2 } from '@aaa-ncnu-ie/ez-quote-api-driver';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useActionMakeRequest } from '../../makeRequest';
import { driverKeys } from './driverKeys';
import { GetAllDriversResponse, useMapToUIDriver } from './useGetAllDrivers';

export const useUpdateSelectedDrivers = ({
  onSuccess,
}: {
  onSuccess?: ((params: GetAllDriversResponse) => void) | null;
}) => {
  const { actionCall: action } = useActionMakeRequest('selectDrivers');

  const queryClient = useQueryClient();
  const transform = useMapToUIDriver();

  return useMutation({
    mutationKey: driverKeys.mutations.updateSelectedDrivers(),
    mutationFn: async (request: UpdateDriverSelectionRequestV2) => {
      const driversResponse = await action(request);

      const response = transform(driversResponse);

      queryClient.setQueryData<typeof response>(
        driverKeys.queries.getAll(),
        () => response,
      );

      onSuccess?.(response);
      return response;
    },
  });
};
