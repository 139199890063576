import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  MetadataResponse,
  PropertyDetails,
} from '@aaa-ncnu-ie/ez-quote-api-property';
import { ContainerProps } from '@mui/material';
import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import { PolicyType } from 'api/schema/insured.schema';
import { useGetPropertyMetadata } from '../../api/queries/property';
import { InsuredContext } from './InsuredContext';

export type PropertyContextType = {
  propertyState: PropertyDetails;
  metaDataState: MetadataResponse;
  getProperty: () => void;
};

const defaultContextState: PropertyDetails = {};
const defaultMetaDataState: MetadataResponse = {
  roofType: [],
  qualityGrade: [],
  dwellingUsage: [],
  constructionType: [],
  noFamilyUnits: [],
  numberStories: [],
  garageType: [],
  numberFireplaces: [],
  foundationType: [],
  exteriorWallFinish: [],
};

export const PropertyContext = createContext<PropertyContextType>({
  propertyState: defaultContextState,
  metaDataState: defaultMetaDataState,
  getProperty: () => undefined,
});

const PropertyContextProvider: React.FC<ContainerProps> = (props) => {
  const [propertyState, setPropertyState] = useState(defaultContextState);

  const {
    actionCreators: { getProperty },
  } = useActionCreators();
  const makeRequest = useRequest();
  const [insuredContextState] = useContext(InsuredContext);

  const getPropertyData = React.useCallback(() => {
    makeRequest(getProperty, (property) => {
      setPropertyState((prevState) => ({
        ...prevState,
        ...property,
      }));
    });
  }, [getProperty, makeRequest]);

  useEffect(() => {
    if (
      insuredContextState.fetched &&
      !(
        propertyState?.constructionType ||
        propertyState?.dwellingUsage ||
        propertyState?.qualityGrade
      ) &&
      insuredContextState?.insured?.product === PolicyType.RENTERS
    ) {
      getPropertyData();
    }
  }, [
    getPropertyData,
    insuredContextState.fetched,
    insuredContextState?.insured?.product,
  ]);

  const { data: metaDataState } = useGetPropertyMetadata();

  const providerValue = React.useMemo(
    () => ({
      propertyState,
      metaDataState: metaDataState ?? defaultMetaDataState,
      getProperty: getPropertyData,
    }),
    [propertyState, metaDataState, getPropertyData]
  );

  return (
    <PropertyContext.Provider value={providerValue}>
      {props.children}
    </PropertyContext.Provider>
  );
};

export default PropertyContextProvider;
