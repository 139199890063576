import { FormValues } from 'components/forms/formTypes';
import { FormikTouched } from 'formik';

export const isPristine = (touched: FormikTouched<any>): boolean =>
  JSON.stringify(touched) === '{}';

export const OnHydrated = {
  callbacks: [] as Array<(values: Partial<FormValues>) => void>,
  subscribe: (cb: (values: Partial<FormValues>) => void) =>
    OnHydrated.callbacks.push(cb),
  publish: (values: Partial<FormValues>) =>
    OnHydrated.callbacks.forEach((cb) => cb(values)),
};

export const OnStartOver = {
  callbacks: [] as Array<() => void>,
  subscribe: (cb: () => void) => OnStartOver.callbacks.push(cb),
  publish: () => OnStartOver.callbacks.forEach((cb) => cb()),
};
