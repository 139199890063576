import React, { createContext, useContext, useEffect, useState } from 'react';
import { ContainerProps } from '@mui/material';
import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import {
  ApiQuoteSummaryResponse,
  EffectiveDatePatchRequest,
} from 'api/schema/quote.schema';
import { setGtmDataLayer } from '../../helpers/gtm';
import { getTotalPremiumAmount } from '../../helpers/premium';
import { InsuredContext } from './InsuredContext';

export type SetQuoteContextState = (newState: ApiQuoteSummaryResponse) => void;
export type SetQuoteElementState = (newState: QuoteElementState) => void;
export type ResetQuoteContextState = () => void;

export type QuoteContext = {
  updateQuoteEffectiveDate: (
    request: EffectiveDatePatchRequest,
  ) => Promise<undefined>;
  quoteState: ApiQuoteSummaryResponse;
  quoteElementState: QuoteElementState;
  setQuoteState: SetQuoteContextState;
  setQuoteElementState: SetQuoteElementState;
  resetQuoteState: ResetQuoteContextState;
  fetchQuoteSummary: () => Promise<ApiQuoteSummaryResponse | undefined>;
  updateQuoteSummary: (updatedProps: Partial<ApiQuoteSummaryResponse>) => void;
};

type QuoteElementState = {
  cartOpen: boolean;
  saveQuoteModalOpen: boolean;
  isInactiveSessionSaveQuoteModalOpen: boolean;
};

const defaultElementState: QuoteElementState = {
  cartOpen: false,
  saveQuoteModalOpen: false,
  isInactiveSessionSaveQuoteModalOpen: false,
};

const defaultContextState: ApiQuoteSummaryResponse = {
  quoteNumber: '',
  effectiveDate: '',
  effectiveDateAllowedFrom: '',
  effectiveDateAllowedTo: '',
  paymentPlans: [],
  policyTerm: 0,
  fetched: false,
  quoteInfo: undefined,
};

export const QuoteContext = createContext<QuoteContext>({
  updateQuoteEffectiveDate: () => Promise.resolve(undefined),
  quoteState: defaultContextState,
  quoteElementState: defaultElementState,
  setQuoteState: () => undefined,
  setQuoteElementState: () => undefined,
  resetQuoteState: () => undefined,
  fetchQuoteSummary: () => Promise.resolve(undefined),
  updateQuoteSummary: () => void 0,
});

const QuoteContextProvider: React.FC<ContainerProps> = (props) => {
  const [quoteState, setQuoteState] = useState(defaultContextState);
  const [quoteElementState, setQuoteElementState] =
    useState(defaultElementState);
  const [insuredContextState] = useContext(InsuredContext);
  const resetQuoteState = () => setQuoteState(defaultContextState);
  const makeRequest = useRequest();
  const {
    actionCreators: { quoteSummary, updateEffectiveDate },
  } = useActionCreators();

  const updateQuoteSummary = (
    updatedProps: Partial<ApiQuoteSummaryResponse>,
  ) => {
    setQuoteState((prevState) => {
      return { ...prevState, ...updatedProps, fetched: true };
    });
    const premium = getTotalPremiumAmount(
      updatedProps?.paymentPlans,
      updatedProps?.paymentPlanSelected,
    );
    setGtmDataLayer({ premium });
  };
  const updateQuoteEffectiveDate = (
    req: EffectiveDatePatchRequest,
  ): Promise<undefined> => {
    return makeRequest(() => updateEffectiveDate(null, req));
  };

  const fetchQuoteSummary = () => {
    return makeRequest(quoteSummary, updateQuoteSummary);
  };

  useEffect(() => {
    if (insuredContextState.isFresh === false && insuredContextState.fetched) {
      fetchQuoteSummary();
    }
  }, [insuredContextState.isFresh, insuredContextState.fetched]);

  const providerValue = {
    updateQuoteEffectiveDate,
    quoteState,
    setQuoteState,
    quoteElementState,
    setQuoteElementState,
    resetQuoteState,
    fetchQuoteSummary,
    updateQuoteSummary,
  };

  return (
    <QuoteContext.Provider value={providerValue}>
      {props.children}
    </QuoteContext.Provider>
  );
};

export default QuoteContextProvider;
