import React, { useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress, LinearProgress, Typography } from '@mui/material';
import { colors, getZIndexForPreference } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';

type LoadingTypes = {
  message?: string;
  messageList?: string[];
  transparent?: boolean;
};

const loadingPageStyles = makeStyles()(({ breakpoints }) => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    width: '80%',
    height: '100vh',
    margin: '0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    [breakpoints.only('mobile')]: {
      minHeight: '300px',
    },
  },
  typography: {
    marginBottom: '10px',
    color: colors.grey100,
    fontSize: '18px',
    lineHeight: '25.2px',
  },
  circularProgressContainer: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    background: colors.white,
    zIndex: getZIndexForPreference(0),
  },
  transparent: {
    opacity: 0.5,
  },
}));

export const CircularLoader = ({ transparent }: LoadingTypes) => {
  const { classes } = loadingPageStyles();

  // NOTE: This prevents keyboard navigation when loader is visible
  // The problem was that user could change data and press buttons
  // with their keyboard
  useEffect(() => {
    const root = document.querySelector('#root');
    root && root.setAttribute('aria-hidden', 'true');
    const listener: EventListener = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    document.addEventListener('keydown', listener, { capture: true });
    return () => {
      root && root.setAttribute('aria-hidden', 'false');
      document.removeEventListener('keydown', listener, { capture: true });
    };
  }, []);

  return (
    <div
      id="loader"
      className={`${classes.circularProgressContainer} ${
        transparent ? classes.transparent : ''
      } `}
    >
      <CircularProgress
        style={{
          marginLeft: 'calc(50vw - 20px)',
          marginTop: 'calc(50vh - 20px)',
        }}
      />
    </div>
  );
};

const Loading = ({ message, messageList }: LoadingTypes) => {
  const { classes } = loadingPageStyles();
  const [messageToShow, setMessageToShow] = useState<string | undefined>();
  const [count, setCount] = useState(0);

  const counter = () => {
    messageList && setCount((count + 1) % messageList?.length);
  };

  useEffect(() => {
    if (messageList) {
      setMessageToShow(messageList[count]);
    }
  }, [count]);

  useEffect(() => {
    if (messageToShow) {
      const timer = setInterval(() => counter(), 3000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [messageToShow]);

  return (
    <div className={classes.container}>
      <Typography className={classes.typography}>
        {messageToShow || message}
      </Typography>
      <LinearProgress style={{ width: '100%' }} />
    </div>
  );
};

export default Loading;
