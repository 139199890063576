import { useContext } from 'react';
import {
  VehicleDescription,
  VehicleUsageType,
} from 'components/forms/formTypes';
import { useQuery } from '../helpers/routing';
import { VehicleContext } from 'components/contexts/VehicleContext';
import { sortVehicles } from 'helpers/vehicles';
import { VIN_REGEX, VIN_REGEX_LETTERS } from 'components/validations';

export const useVehicleFromRoute = (
  id?: string
): [VehicleDescription | undefined, string] => {
  const { getVehiclesById } = useContext(VehicleContext);
  const query = useQuery();
  const vehicleId = query?.get('vehicleId') || '';

  const vehicles: VehicleDescription[] | undefined = getVehiclesById([
    id || vehicleId,
  ]);

  return [(vehicles && vehicles[0]) || {}, vehicleId];
};

export const useVehicleCompanyInfo = (): [
  VehicleDescription[] | undefined,
  VehicleDescription | undefined,
  VehicleDescription[] | undefined
] => {
  const { vehicleContextState } = useContext(VehicleContext);
  const incompleteVehicleCompanyInfo: VehicleDescription[] | undefined =
    vehicleContextState?.vehicles?.filter(
      (vehicle) =>
        vehicle.selected &&
        vehicle.ownership !== 'OWN' 
    );

  const skippedVehicleCompanyInfo: VehicleDescription[] | undefined =
    vehicleContextState?.vehicles?.filter(
      (vehicle) =>
        vehicle.selected &&
        vehicle.ownership !== 'OWN' &&
        vehicle.skippedCompanyInfo
    );

  const query = useQuery();
  const vehicleId = query?.get('vehicleId') || '';
  const vehicle = vehicleContextState?.vehicles?.find(
    (vehicle) =>
      vehicle.selected &&
      vehicle.ownership !== 'OWN' &&
      vehicle.vehicleId === vehicleId
  );

  return [
    sortVehicles(incompleteVehicleCompanyInfo) || [],
    vehicle,
    sortVehicles(skippedVehicleCompanyInfo) || [],
  ];
};

export const useBusinessVehicles = (): [VehicleDescription[]] => {
  const { vehicleContextState } = useContext(VehicleContext);
  const businessVehiclesList: VehicleDescription[] =
    vehicleContextState?.vehicles?.filter(
      (vehicle) =>
        vehicle.selected && vehicle.usageType === VehicleUsageType.Business
    );
  return [sortVehicles(businessVehiclesList) || []];
};

export const useInvalidVinVehicles = (): [VehicleDescription[]] => {
  const { vehicleContextState } = useContext(VehicleContext);
  const vinVehiclesList: VehicleDescription[] =
    vehicleContextState?.vehicles?.filter(
      (vehicle) =>
        vehicle.vin &&
        vehicle.selected &&
        (!VIN_REGEX.test(vehicle.vin) || !VIN_REGEX_LETTERS.test(vehicle.vin))
    );
  return [sortVehicles(vinVehiclesList) || []];
};

export const useAddedUsingVinVehicles = (): [VehicleDescription[]] => {
  const { vehicleContextState } = useContext(VehicleContext);
  const vinVehiclesList: VehicleDescription[] =
    vehicleContextState?.vehicles?.filter(
      (vehicle) =>
        !vehicle.addedUsingVin &&
        vehicle.selected
    );
  return [sortVehicles(vinVehiclesList) || []];
};
