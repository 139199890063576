import React, { ErrorInfo } from 'react';
import { getPathByName } from 'helpers/pages';

class ErrorBoundary extends React.Component<
  { hasError?: boolean; children?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { hasError: boolean }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    import.meta.env.PROD === false && console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      window.history.pushState(
        {},
        'error',
        `${getPathByName('ErrorPage')}?reason=error`
      );
      // OnStartOver.publish();
      return this.props.children;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
