import { QueryKey } from '@tanstack/react-query';
import { getCookie } from '../../helpers/cookies';
import { hasText } from '../../helpers/functions';
import { QueryOptions } from './types';

export const getSessionId = () => `${getCookie('sessionId')}`;
export const getSessionIdKey = () => `sessionId-${getSessionId()}`;

/**
 * Set some default options.
 *  - only enable if sessionId is present
 *
 * @param options
 * @returns query options
 */
export const getQueryOptions = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options?: QueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => ({
  ...(options ?? {}),
  enabled: (options?.enabled ?? true) && hasText(getSessionId()),
});
