import { PropertyMetadataRequest } from '../../schema/property.schema';
import { getSessionIdKey } from '../utils';

// Ref: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

export const propertyKeys = {
  common: () => {
    const sessionIdKey = getSessionIdKey();
    return [sessionIdKey, 'property'] as const;
  },
  queries: {
    get: () => [...propertyKeys.common(), 'get'] as const,
    getOptionalAmenities: () =>
      [...propertyKeys.common(), 'get', 'optional-amenities'] as const,
    getMortgageDetails: () =>
      [...propertyKeys.common(), 'get', 'mortgage-details'] as const,
    getMetadata: (params: PropertyMetadataRequest) =>
      [...propertyKeys.common(), 'get', 'metadata', params] as const,
    getRenovations: () =>
      [...propertyKeys.common(), 'get', 'renovations'] as const,
    getProperty: () => [...propertyKeys.common(), 'get', 'property'] as const,
    getDwellingDetails: () =>
      [...propertyKeys.common(), 'get', 'dwelling-details'] as const,
  },
  mutations: {
    updatePropertyOptionalAmenities: () =>
      [...propertyKeys.common(), 'updatePropertyOptionalAmenities'] as const,
    updateMortgageDetails: () =>
      [...propertyKeys.common(), 'updateMortgageDetails'] as const,
    updateRenovations: () =>
      [...propertyKeys.common(), 'updateRenovations'] as const,
    updateDwellingDetails: () =>
      [...propertyKeys.common(), 'updateDwellingDetails'] as const,
    updateUnderwritingQuestion: () =>
      [...propertyKeys.common(), 'updateUnderwritingQuestion'] as const,
  },
};
