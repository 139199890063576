export * from '@aaa-ncnu-ie/ez-quote-api-insured';

// Enum dependencies are not guaranteed to be stable when imported directly from the schema.
// This is why we override them so that when API adds updates the UI code doesn't break.
export enum PolicyType {
  AUTO = 'AUTO',
  HOMEOWNERS = 'HO3',
  RENTERS = 'HO4',
  CONDO = 'HO6',
  LANDLORD = 'DP3',
}

export enum ResidenceType {
  OWNHOME = 'OWNHOME',
  OWNCONDO = 'OWNCONDO',
  RENT = 'RENT',
  OTHER = 'OTHER',
}
