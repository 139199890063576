import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useActionMakeRequest } from '../../makeRequest';
import { PropertyDetails } from '../../schema/property.schema';
import { QueryOptions } from '../types';
import { getQueryOptions } from '../utils';
import { propertyKeys } from './propertyKeys';

export const useGetProperty = <T = PropertyDetails>(
  options?: QueryOptions<PropertyDetails, unknown, T>
) => {
  const { actionCall: action } = useActionMakeRequest('getProperty');

  return useQuery({
    queryKey: propertyKeys.queries.getProperty(),
    queryFn: () => action(),
    ...getQueryOptions(options),
  });
};

export const useRemoveGetPropertyQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    () =>
      queryClient.removeQueries({
        queryKey: propertyKeys.queries.getProperty(),
      }),
    [queryClient]
  );
};
