export const formatPhoneNumber = (phoneNumber = '') => {
  const formatedPhonenNumber = phoneNumber
    .replace(/\D/g, '')
    .replace(/^(\d{3})(\d{3})(\d{4})$/g, '$1.$2.$3');

  return formatedPhonenNumber;
};

export const formatPhoneNumberAlternative = (phone = '') => {
  const r = phone.replace(/\D/g, '');
  const m = r.match(/^(\d{1,3})(\d{1,3})?(\d{1,})?$/);
  const area = (m?.[1] && `(${m[1]}`) ?? '';
  const part01 = (m?.[2] && `) ${m[2]}`) ?? '';
  const part02 = (m?.[3] && `-${m[3].substring(0, 4)}`) ?? '';
  return area + part01 + part02;
};
