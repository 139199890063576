import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory, useLocation } from 'react-router-dom';
import { ExitReason } from '@aaa-ncnu-ie/ez-quote-session-types';
import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import { useFormikContext } from 'formik';
import { useExitQuote } from 'hooks/useExitQuote';
import useSegment from 'hooks/useSegment';
import { useStartOver } from 'hooks/useStartOver';
import { InsuredContext } from 'components/contexts/InsuredContext';
import { ModalContext } from 'components/contexts/ModalContext';
import { QuoteContext } from 'components/contexts/QuoteContext';
import { FormValues } from 'components/forms/formTypes';
import {
  getNameByPath,
  getPathByName,
  getTabIndexByPath,
  Tabs as ETabs,
} from '../../helpers/pages';
import SaveQuoteModalForm from '../modals/SaveQuoteModal';
import SessionInactiveModal from '../modals/SessionInactiveModal';
import {
  canRenew,
  PageConfigs,
  sessionTimeoutFeatureEnabled,
  TimerConfigs,
} from './configs';

const IdleTimer = () => {
  //context
  const {
    quoteElementState,
    quoteElementState: { isInactiveSessionSaveQuoteModalOpen },
    setQuoteElementState,
  } = useContext(QuoteContext);
  const [insuredContext] = useContext(InsuredContext);
  const { handleModal, modalContent } = useContext(ModalContext);
  const { errors, values } = useFormikContext<Partial<FormValues>>();
  const history = useHistory();
  //local State
  const [currentTabIndex, setCurrentTabIndex] = useState<number | boolean>(
    false,
  );
  const [nextRenewal, setNextRenewal] = useState<number>(1);
  const [state, setState] = useState<string>('Idle');
  const [
    activeTimeAllowedBeforeRenewal,
    setActiveTimeAllowedBeforeRenewalState,
  ] = useState(TimerConfigs.activeTimeAllowedBeforeRenewalLength);

  //local state for idleTimerconfigs
  const { pathname } = useLocation();
  const pageName = getNameByPath('/' + pathname.split('/')[1]);
  const [currentPageConfigs, setCurrentPageConfigs] = useState(
    PageConfigs[pageName],
  );
  const [isCurrentlyLongIdle, setIsCurrentlyLongIdle] = useState(
    !!currentPageConfigs?.isLongidle,
  );
  const [idleTimerLength, setIdleTimerLength] = useState(
    isCurrentlyLongIdle
      ? TimerConfigs.longIdleLength
      : TimerConfigs.shortIdleLength,
  );

  const [isIdleTimerModalDisplayed, setIsIdleTimerModalDisplayed] =
    useState<boolean>(false);
  //hooks
  const { t } = useTranslation();
  const { track } = useSegment();
  const {
    actionCreators: { renewSession, canSessionContinue },
  } = useActionCreators();
  const { exitQuote } = useExitQuote();
  const makeRequest = useRequest();
  const { startOver } = useStartOver();
  //variables
  const promptBeforeIdleLength = TimerConfigs.promptBeforeIdleLength;
  const emailAddress = insuredContext?.insured?.email || values.email || '';
  const phoneNumber =
    insuredContext?.insured?.phoneNumber || values.cellNumber || '';
  const isContactInfoValid = Boolean(
    !(errors.email || errors.cellNumber) && values.email && values.cellNumber,
  );
  const hasPassedDiscountSummary = isCurrentlyLongIdle;
  const isErrorPage = pathname.toLowerCase().includes('error');

  const isSessionTimeoutModalDisplayed =
    modalContent?.key === 'session-timeout-modal';
  const disabled =
    !!currentPageConfigs?.excluded ||
    isErrorPage ||
    isSessionTimeoutModalDisplayed ||
    (isContactInfoValid && isInactiveSessionSaveQuoteModalOpen);

  const onIdle = () => {
    setState('Idle');
    console.debug(':::: idleTimer: onIdle was called', {
      isIdleTimerModalDisplayed,
      isInactiveSessionSaveQuoteModalOpen,
      isContactInfoValid,
    });
    if (isIdleTimerModalDisplayed && !isInactiveSessionSaveQuoteModalOpen) {
      if (isContactInfoValid) {
        exitSession();
      } else {
        setIsIdleTimerModalDisplayed(false);
        toggleSaveQuoteModal(true);
        triggerAdditionalPrompt();
      }
    } else if (
      !isIdleTimerModalDisplayed &&
      isInactiveSessionSaveQuoteModalOpen
    ) {
      exitSession();
    } else {
      return;
    }
  };

  const onActive = () => {
    setState('Active');
    console.debug(':::: idleTimer: onActive was called');
  };

  const onPrompt = () => {
    setState('Prompted');
    console.debug(':::: idleTimer: onPrompt was called', {
      isIdleTimerModalDisplayed,
      isInactiveSessionSaveQuoteModalOpen,
      isContactInfoValid,
    });
    if (!isIdleTimerModalDisplayed && !isInactiveSessionSaveQuoteModalOpen) {
      setIsIdleTimerModalDisplayed(true);
    } else if (
      isIdleTimerModalDisplayed &&
      !isInactiveSessionSaveQuoteModalOpen
    ) {
      if (isContactInfoValid) {
        exitSession();
      } else {
        setIsIdleTimerModalDisplayed(false);
        toggleSaveQuoteModal(true);
      }
    } else {
      return;
    }
  };

  const onAction = async (event?: Event) => {
    const elapsedActiveTime = getElapsedTime();
    console.debug(':::: idleTimer: onAction was called', {
      event: event?.type,
      elapsedActiveTime,
      activeTimeAllowedBeforeRenewal,
      nextRenewal,
    });

    if (
      elapsedActiveTime >= nextRenewal * activeTimeAllowedBeforeRenewal &&
      !isIdleTimerModalDisplayed &&
      !isInactiveSessionSaveQuoteModalOpen
    ) {
      if (isContactInfoValid) {
        state === 'Idle' && exitSession();
      } else {
        const shouldAttemptRenewal =
          !sessionTimeoutFeatureEnabled ||
          (sessionTimeoutFeatureEnabled &&
            (canRenew || (await sessionCanContinue())));

        shouldAttemptRenewal
          ? renewSession().then(() => setNextRenewal(nextRenewal + 1))
          : exitSession();
      }
    }
  };

  //idleTimer hook, configs, etc
  const { activate, pause, getElapsedTime } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onAction,
    disabled,
    events: TimerConfigs.eventsToListenFor,
    startManually: !!currentPageConfigs?.excluded,
    stopOnIdle: true,
    timeout: idleTimerLength,
    promptBeforeIdle: promptBeforeIdleLength,
    debounce: 1000,
  });

  //native react hooks
  useEffect(() => {
    isErrorPage ? pause() : setCurrentTabIndex(getTabIndexByPath(pathname));
  }, [pathname]);

  useEffect(() => {
    handlePageChange(PageConfigs[pageName]);
  }, [pageName]);

  //custom Methods
  const toggleSaveQuoteModal = (shouldOpen: boolean) => {
    isInactiveSessionSaveQuoteModalOpen !== shouldOpen &&
      setQuoteElementState({
        ...quoteElementState,
        isInactiveSessionSaveQuoteModalOpen: shouldOpen,
      });
  };

  const sessionCanContinue = async (): Promise<boolean> => {
    const response = await makeRequest(() => canSessionContinue());
    return !!response?.canContinue;
  };

  const exitSession = async () => {
    if (!isContactInfoValid) {
      handleBothModalClose();
      displaySessionTimeoutModal();
    } else {
      pause();
      const isAfterQuotePages = isCurrentlyLongIdle && pageName !== 'Quote';
      const pageLastViewed = window.location.pathname + window.location.search;
      const formattedPhone = phoneNumber.replaceAll(/\D/gi, '');

      await exitQuote({
        email: emailAddress,
        phoneNumber: formattedPhone,
        exitReason: isAfterQuotePages
          ? ExitReason.PURCHASE_ABANDONED
          : ExitReason.ABANDONED,
        pageLastViewed,
      })
        .then(() => history.push(getPathByName('ThankYou')))
        .catch(() => displaySessionTimeoutModal())
        .then(() => handleBothModalClose());
    }
  };

  const displaySessionTimeoutModal = () => {
    handleModal({
      key: 'session-timeout-modal',
      heading: t('sessiontimeoutmodal.title'),
      onCancel: startOver,
      cancelButtonText: t('sessiontimeoutmodal.cancelButtonText'),
    });
  };

  const setActiveTimeAllowedBeforeRenewal = () => {
    const elapsedTime = getElapsedTime();
    const leftOverActiveTime = activeTimeAllowedBeforeRenewal - elapsedTime;
    const activeTimeAllowed = leftOverActiveTime > 0 ? leftOverActiveTime : 0;
    setActiveTimeAllowedBeforeRenewalState(activeTimeAllowed);
    return activeTimeAllowed;
  };

  const handlePageChange = (
    pageConfigs: { excluded?: boolean; isLongIdle?: boolean } = {},
  ) => {
    setCurrentPageConfigs(pageConfigs);
    setNextRenewal(1);
    const updatedLongIdleState = !!pageConfigs?.isLongIdle;
    if (!pageConfigs?.excluded) {
      const { shortIdleLength, longIdleLength } = TimerConfigs;
      if (updatedLongIdleState !== isCurrentlyLongIdle) {
        const newTime = updatedLongIdleState ? longIdleLength : shortIdleLength;
        setIdleTimerLength(newTime);
        setIsCurrentlyLongIdle(updatedLongIdleState);
        pause();
        setActiveTimeAllowedBeforeRenewal();
      }
      state !== 'Active' && activate();
    } else {
      pause();
    }
  };

  const handleInactiveIsStillThere = async () => {
    pause();
    const shouldAttemptRenewal =
      !sessionTimeoutFeatureEnabled ||
      (sessionTimeoutFeatureEnabled &&
        (canRenew || (await sessionCanContinue())));
    shouldAttemptRenewal
      ? renewTheSession()
          .then(() => {
            setActiveTimeAllowedBeforeRenewalState(
              TimerConfigs.activeTimeAllowedBeforeRenewalLength,
            );
            setIsIdleTimerModalDisplayed(false);
            activate();
          })
          .catch(() => {
            exitSession();
          })
      : exitSession();
  };

  const renewTheSession = () => {
    pause();
    if (!sessionTimeoutFeatureEnabled) {
      return new Promise((resolve) => resolve(1));
    } else {
      return makeRequest(
        () => renewSession().then((res) => res?.errorCode && exitSession()),
        () => {
          setActiveTimeAllowedBeforeRenewalState(
            TimerConfigs.activeTimeAllowedBeforeRenewalLength,
          );
          handleBothModalClose();
        },
      );
    }
  };

  const triggerAdditionalPrompt = () => {
    pause();
    setIdleTimerLength(promptBeforeIdleLength + 1);
  };

  const handleIdleTimerModalAffirmativeClick = () => {
    handleInactiveIsStillThere();
    track('Inactivity Response Provided', {
      eventType: 'Option Selected',
      selection: 'yes',
    });
  };

  const handleIdleTimerModalNegativeClick = () => {
    if (isContactInfoValid) {
      pause();
      exitSession();
    } else {
      triggerAdditionalPrompt();
    }
    track('Inactivity Response Provided', {
      eventType: 'Option Selected',
      selection: 'no',
    });
  };

  const handleIdleTimerXClick = () => {
    handleInactiveIsStillThere();
    track('Cancel Selected', {
      eventType: 'UX Modified',
    });
  };

  const handleBothModalClose = () => {
    isInactiveSessionSaveQuoteModalOpen && toggleSaveQuoteModal(false);
    isIdleTimerModalDisplayed && setIsIdleTimerModalDisplayed(false);
  };

  return (
    <>
      {isIdleTimerModalDisplayed && (
        <SessionInactiveModal
          open={isIdleTimerModalDisplayed}
          onAffirmative={handleIdleTimerModalAffirmativeClick}
          onNegative={handleIdleTimerModalNegativeClick}
          onClose={handleIdleTimerXClick}
        />
      )}

      {isInactiveSessionSaveQuoteModalOpen && (
        <SaveQuoteModalForm
          hasPassedDiscountSummary={hasPassedDiscountSummary}
          handleModalClose={handleBothModalClose}
          open={isInactiveSessionSaveQuoteModalOpen}
          onClose={() => exitSession()}
          currentTabIndex={currentTabIndex as ETabs}
          text={t('savequotemodal.timeoutText')}
        />
      )}
    </>
  );
};

export default IdleTimer;
