import React, { ReactElement } from 'react';
import { Field, FieldAttributes } from 'formik';

// This component solves the problem with the Field component
// not being able to infer types of the props of the component passed into it
type TypedFieldProps<P> = Omit<P, 'field' | 'meta' | 'form'> &
  Omit<FieldAttributes<any>, keyof P> & {
    component: React.ComponentType<P>;
    name: string;
    id: string;
  };

export function TypedField<P>(props: TypedFieldProps<P>): ReactElement<P> {
  return (<Field {...props} />) as unknown as ReactElement<P>;
}
