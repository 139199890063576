import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Breakpoints } from '@mui/material/styles';
import { useDeviceTypes } from 'helpers/devices';
import useSegment from 'hooks/useSegment';
import { isEmpty } from 'lodash';
import { colors } from 'styles/theme';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/common/Button';
import { ModalContext } from 'components/contexts/ModalContext';
import { Spacer } from 'components/layouts/Spacer';
import Modal from '../common/Modal';

const useStyles = makeStyles()(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '480px',
    padding: '16px 32px 64px 32px',
    overflow: 'hidden',
  },
  text: {
    marginBottom: '24px',
    fontFamily: 'Cabin',
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 500,
    color: colors.grey60,
  },
  button: {
    height: '42px',
  },
}));

const SimpleModal = () => {
  const { modalContent, handleModal, modal } = useContext(ModalContext);
  const { track } = useSegment();
  const { isMobile } = useDeviceTypes();
  const { classes } = useStyles();
  const [initialTrackingEventFired, updateInitialTrackingEventFired] =
    useState(false);

  useEffect(() => {
    if (
      !isEmpty(modalContent) &&
      modalContent.key === 'session-timeout-modal' &&
      !initialTrackingEventFired
    ) {
      track('Session Timeout Displayed', {
        eventType: 'Messaged',
      });
      updateInitialTrackingEventFired(true);
    }
  }, [modalContent, initialTrackingEventFired]);

  const onClose = () => {
    track('Cancel Selected', {
      eventType: 'UX Modified',
    });
    handleModal();
    if (modalContent?.onCancel) modalContent.onCancel();
  };

  const link_destination =
    modalContent.key === 'session-timeout-modal' ? 'Entry' : undefined;

  return (
    <Modal
      testId={modalContent.key}
      fullWidth={isMobile}
      maxWidth={'tablet'}
      title={modalContent.heading}
      open={modal}
      onClose={onClose}
      modalName=""
    >
      <div className={classes.form}>
        {modalContent.content && (
          <Typography className={classes.text}>
            {modalContent.content}
          </Typography>
        )}
        {modalContent.cancelButtonText && (
          <>
            <Spacer space={16} />
            <Button
              fullWidth={true}
              color="primary"
              csaaType="primary"
              variant="contained"
              classes={{ root: classes.button }}
              onClick={() => {
                track('Link Accessed', {
                  link: modalContent.cancelButtonText,
                  link_placement: modalContent.key,
                  link_destination,
                  eventType: 'Link Accessed',
                });
                if (modalContent?.onCancel) modalContent.onCancel();
                handleModal();
              }}
            >
              {modalContent.cancelButtonText}
            </Button>
          </>
        )}
        {modalContent.confirmButtonText && (
          <>
            <Spacer space={16} />
            <Button
              fullWidth={true}
              color="secondary"
              csaaType="secondary"
              variant="contained"
              classes={{ root: classes.button }}
              onClick={() => {
                if (modalContent?.onConfirm) modalContent.onConfirm();
                handleModal();
              }}
            >
              {modalContent.confirmButtonText}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SimpleModal;
