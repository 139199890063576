import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles';

// NOTE: This significantly simplifies z-index management
/**
 * @param preference
 * 0 - Loading Overlay;
 * 1 - Components that should display over navbar;
 * 2 - Navbar, modals, etc.;
 * 3 - Input Popovers;
 * 4 - Reserved;
 * 5 - Reserved;
 * 6 - Components that should display over the chat bubble;
 * -1 - Everything else;
 * @returns
 */
export const getZIndexForPreference = (preference: number) =>
  [6, 5, 4, 3, 2, 1, 1300][preference] || 0;

export const colors = {
  saberBlue: '#1778D3',
  primaryBlue50: '#115DC4',
  primaryBlue70: '#00338D',
  spectrumBlue60: '#0044AC',
  linkBlue: '#175AC4',
  lightBlue: '#5196E5',
  blue05: '#D3E6FA',
  blue50: '#115DC4',
  infoblue: '#F2F9FF',
  redLeader: '#D52B1E',
  yellow0: '#FFFAE0',
  white: '#FFFFFF',
  black: '#0B1421',
  grey5: '#F7F8FC',
  grey10: '#E3E5EE',
  grey20: '#CFD2E0',
  grey30: '#ABAFBF',
  grey50: '#676C7D',
  grey60: '#4D5160',
  grey70: '#373B49',
  grey80: '#262A37',
  grey90: '#191E2B',
  grey100: '#0B1421',
  greyOther: '#5F6062',
  pinkish: '#FFE6E6',
  transparentSemiWhite: 'rgb(255, 255, 255, 0.6)',
  transparentWhite: 'rgb(255, 255, 255, 0.75)',
  transparentDeepWhite: 'rgba(255, 255, 255, .98)',
};

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
}

const theme: Theme = createTheme({
  breakpoints: {
    values: { mobile: 0, tablet: 768, desktop: 1440 },
  },
  shape: { borderRadius: 4 },
  typography: {
    fontFamily:
      'Slash,RT Raleway,Raleway,Work Sans,Cabin,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    h1: { fontSize: '2.75rem', fontWeight: 500, lineHeight: 3.875 },
    body1: {
      fontSize: '1.125rem',
      lineHeight: 1.5625,
      color: colors.grey60,
    },
    body2: {
      fontFamily: 'Cabin',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.225,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {},
  palette: {
    text: { primary: colors.black },
    mode: 'light',
    common: { black: colors.black, white: colors.white },
    background: { default: colors.grey5 },
    primary: { main: colors.primaryBlue50, contrastText: colors.primaryBlue50 },
    error: { main: colors.redLeader },
    info: { main: colors.grey60 },
    action: { disabledBackground: colors.grey10 },
  },
});

export default responsiveFontSizes(theme);
