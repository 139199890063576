import React, { createContext, useCallback, useState } from 'react';
import { ContainerProps } from '@mui/material';
import { Action, Location } from 'history';

export type UnregisterHandler = () => void;
export type Handler = (location: Location, action: Action) => boolean;

export type BackHandlerContext = {
  register: (handler: Handler) => UnregisterHandler;
  handlers: Handler[];
};

export const BackHandlerContext = createContext<BackHandlerContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  register: () => () => {
    /* EMPTY */
  },
  handlers: [],
});

const BackHandlerContextProvider: React.FC<ContainerProps> = (props) => {
  const [handlers, setHandlers] = useState<Handler[]>([]);

  const register = useCallback((handler: Handler) => {
    setHandlers((_handlers) => [..._handlers, handler]);

    return () => {
      setHandlers((_handlers) => _handlers.filter((h) => h !== handler));
    };
  }, []);

  return (
    <BackHandlerContext.Provider value={{ register, handlers }}>
      {props.children}
    </BackHandlerContext.Provider>
  );
};

export default BackHandlerContextProvider;
