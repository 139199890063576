import moment from 'moment';
import Cookies, { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

export const getCookie = (name: string) => {
  const cookie = cookies.get(name);
  const isEmpty = !cookie || ['undefined', 'null', ''].includes(cookie);
  return !isEmpty ? cookie : '';
};

const _setCookie = (
  name: string,
  value: unknown,
  options?: CookieSetOptions
) => {
  const secure = import.meta.env.PROD;
  cookies.set(name, value, {
    path: '/',
    secure,
    httpOnly: false,
    sameSite: 'lax',
    expires: new Date(moment().add(2, 'h').format()),
    ...options,
  });
};

export const setCookie = (
  name: string,
  value: unknown,
  options?: CookieSetOptions
) => {
  value && _setCookie(name, value, options);
};

export const removeCookie = (name: string) => {
  cookies.remove(name);
};

export const resetCookie = (name: string) => _setCookie(name, undefined);
