import { useCallback, useContext, useMemo, useRef } from 'react';
import { QuoteType } from '@aaa-ncnu-ie/ez-quote-session-types';
import { PolicyType } from 'api/schema/insured.schema';
import { useFormikContext } from 'formik';
import { getCookie } from 'helpers/cookies';
import { useDeviceTypes } from 'helpers/devices';
import { reassignProperty } from 'helpers/functions';
import { getKeyByPath } from 'helpers/pages';
import { PRODUCT_DESCRIPTIONS } from 'helpers/quote';
import useUrlQuery from 'hooks/useUrlQuery';
import { ClubContext } from 'components/contexts/ClubContext';
import { InsuredContext } from 'components/contexts/InsuredContext';
import { QuoteContext } from 'components/contexts/QuoteContext';
import { FormValues } from 'components/forms/formTypes';
import { useSendPageEvent, useSendTrackEvent } from '../api/queries/event';

export type CSAAEventTypes =
  | 'File Downloaded'
  | 'Policy Documents Viewed'
  | 'Link Accessed'
  | 'Messaged'
  | 'Option Selected'
  | 'UX Modified'
  | 'Chat / Cobrowse'
  | 'User Information Entered'
  | 'Automated System Process'
  | '';

const policyTypeDictionary = {
  [PolicyType.AUTO]: 'Automobile',
  [PolicyType.HOMEOWNERS]: 'Homeowner',
  [PolicyType.CONDO]: 'Condominium',
  [PolicyType.RENTERS]: 'Renters',
  [PolicyType.LANDLORD]: 'Landlord',
};

const useSegment = () => {
  const { clubState } = useContext(ClubContext);
  const [insuredState] = useContext(InsuredContext);
  const { quoteState } = useContext(QuoteContext);
  const { segmentDeviceType } = useDeviceTypes();
  const { getParam, getParamFromCookie } = useUrlQuery();
  const form = useFormikContext<Partial<FormValues>>();
  const values = form?.values ?? {};

  let productDescription: string | null = null;
  if (quoteState.quoteNumber) {
    const product = quoteState.quoteNumber.substring(3, 5);
    productDescription = PRODUCT_DESCRIPTIONS[product];
  }

  // return the path without '/' as requested
  // if on entry page; get the key from the pages helper
  const category =
    window.location.pathname === '/'
      ? getKeyByPath('/')
      : window.location.pathname.replace('/', '');

  const queryParam = getCookie('queryParam');
  const paramPolicyType = (
    getParam('policy') || getParamFromCookie(queryParam, 'policy', '')
  ).toUpperCase();

  const policyType: string =
    policyTypeDictionary[
      (insuredState.insured?.product ||
        values.product ||
        paramPolicyType) as PolicyType
    ];
  const email = insuredState?.insured?.email ?? null;

  const policyState = (
    insuredState?.state ||
    clubState.state ||
    getParam('state') ||
    getParamFromCookie(queryParam, 'state', undefined)
  )?.toUpperCase();

  const firstBundledQuote = quoteState.quoteInfo?.bundledQuotes?.at(0);
  const firstBundledQuoteStatus =
    !!firstBundledQuote &&
    quoteState.quoteInfo?.quoteStatus?.[firstBundledQuote];

  const globalProperties = useMemo(
    () => ({
      category,
      club_code:
        clubState.clubcode ||
        getParamFromCookie(queryParam, 'clubcode', undefined) ||
        null,
      email: email,
      event_type: null,
      label: null,
      device_type: segmentDeviceType || null,
      policy_type: policyType || null,
      policy_state: policyState || null,
      dmlpid: clubState.dmlpid ? clubState.dmlpid : null,
      gclid:
        getParam('gclid') || getParamFromCookie(queryParam, 'gclid', undefined),
      product_description: productDescription,
      quote_number: quoteState.quoteNumber ? quoteState.quoteNumber : null,
      base_quote: quoteState?.quoteInfo?.baseQuote
        ? quoteState?.quoteInfo?.baseQuote
        : null,
      dmlp_referrer:
        getParamFromCookie(queryParam, 'dm_page', undefined) || null,
      quote_type: quoteState?.quoteInfo?.type || null,
      bundle_quote_1_product:
        quoteState?.quoteInfo?.type === QuoteType.BUNDLE
          ? firstBundledQuote || null
          : null,
      bundle_quote_1_status:
        quoteState?.quoteInfo?.type === QuoteType.BUNDLE
          ? firstBundledQuoteStatus || null
          : null,
      bundle_quote_1_number:
        quoteState?.quoteInfo?.type === QuoteType.BUNDLE
          ? quoteState?.firstBundledQuoteNumber || null
          : null,
    }),
    [
      category,
      clubState.clubcode,
      clubState.dmlpid,
      clubState.source,
      getParamFromCookie,
      queryParam,
      email,
      segmentDeviceType,
      policyType,
      policyState,
      getParam,
      productDescription,
      quoteState.quoteNumber,
      quoteState?.quoteInfo?.baseQuote,
      quoteState?.quoteInfo?.type,
      firstBundledQuote,
      firstBundledQuoteStatus,
      quoteState?.firstBundledQuoteNumber,
    ],
  );

  const globalPropertiesApi = useMemo(
    () => ({
      category,
      device_type: globalProperties.device_type,
      dmlp_referrer: globalProperties.dmlp_referrer,
      policy_type: policyType || undefined,
      email: email,
      club_code:
        clubState.clubcode ||
        getParamFromCookie(queryParam, 'clubcode', undefined) ||
        undefined,
    }),
    [
      category,
      globalProperties.device_type,
      globalProperties.dmlp_referrer,
      policyType,
      email,
      clubState.clubcode,
      getParamFromCookie,
      queryParam,
      getParam,
    ],
  );
  const isNewRelicMonitor = getParam('newRelicMonitor') === 'true';
  const { mutateAsync: sendTrackEvent } = useSendTrackEvent();
  const track = useCallback(
    (eventName: string, properties: Record<string, unknown>) => {
      // The following jank allows this function to be backwards compatible.
      if (properties.eventType) {
        properties = reassignProperty(properties, 'eventType', 'event_type');
      }
      if (properties.errorCode) {
        properties = reassignProperty(properties, 'errorCode', 'error_code');
      }

      const policyState = (
        clubState.state ||
        getParam('state') ||
        getParamFromCookie(queryParam, 'state', undefined)
      )?.toUpperCase();

      const commonProperties = {
        label: eventName,
        dmlpid: clubState.dmlpid ? clubState.dmlpid : null,
        gclid:
          getParam('gclid') ||
          getParamFromCookie(queryParam, 'gclid', undefined),
        policy_state: policyState || undefined,
        utm_campaign:
          getParam('utm_campaign') ||
          getParamFromCookie(queryParam, 'utm_campaign', undefined),
        utm_source:
          getParam('utm_source') ||
          getParamFromCookie(queryParam, 'utm_source', undefined),
        utm_medium:
          getParam('utm_medium') ||
          getParamFromCookie(queryParam, 'utm_medium', undefined),
        ...properties,
      };

      return sendTrackEvent({
        isNewRelicMonitor,
        eventName,
        commonProperties,
        gtmGlobalProperties: {
          ...globalProperties,
          source: getParam('source') || getCookie('source') || 'club',
        },
        apiGlobalProperties: globalPropertiesApi,
      });
    },
    [
      clubState.dmlpid,
      clubState.state,
      getParam,
      getParamFromCookie,
      globalProperties,
      globalPropertiesApi,
      queryParam,
      sendTrackEvent,
    ],
  );

  const { mutateAsync: sendPageEvent } = useSendPageEvent();
  const page = useCallback(
    (pageName: string, properties?: Record<string, unknown>) => {
      return sendPageEvent({
        isNewRelicMonitor,
        pageName,
        properties: {
          ...globalProperties,
          ...properties,
        },
      });
    },
    [globalProperties, sendPageEvent],
  );

  // Since track func has a lot of deps, and is not guaranteed that all are memoized,
  // creating a ref to track func to be used to avoid unnecessary rerenders
  const trackRef = useRef(track);
  if (trackRef.current !== track) {
    trackRef.current = track;
  }

  const pageRef = useRef(page);
  if (pageRef.current !== page) {
    pageRef.current = page;
  }

  const initialize = useCallback(() => {
    /*
     * My Quote analytics functions.
     * This is a custom analytics object that is used redirect analytics events
     * through the API instead of directly to segment.
     * This is necessary so chat events are sent through the API and not directly to segment.
     */
    window.analytics = {
      ...window.analytics,
      track: (...args) => trackRef.current?.(...args),
      page: (...args) => pageRef.current?.(...args),
    };
  }, []);

  return {
    initialize,
    track,
    trackRef,
    page,
  };
};

export default useSegment;
