export * from '@aaa-ncnu-ie/ez-quote-api-discounts';

// Enum dependencies are not guaranteed to be stable when imported directly from the schema.
// This is why we override them so that when API adds updates the UI code doesn't break.
export enum DriverDiscount {
  DefensiveDriver = 'Defensive Driving Course Discount',
  GoodStudent = 'Good Student Discount',
  SmartDriver = 'SMART Driver Discount',
  Travelink = 'Travelink Discount',
}

export enum ResidenceType {
  OWNHOME = 'OWNHOME',
  OWNCONDO = 'OWNCONDO',
  RENT = 'RENT',
}

export enum DiscountName {
  MultiPolicyDiscount = 'Multipolicy Discount',
  FireAlarm = 'Fire Alarm',
  TheftAlarm = 'Theft Alarm',
  ResidentialSprinklers = 'Sprinklers',
}
