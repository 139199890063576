import React, { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TextField, TextFieldProps } from 'formik-mui';
import { fieldNameFormatter } from 'helpers/formatter';
import useSegment from 'hooks/useSegment';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as ErrorIcon } from '../../images/icons/error-icon.svg';
import { colors } from '../../styles/theme';

export const styles = makeStyles()(({ palette }: Theme) => ({
  labelError: {
    color: `${palette.info.main} !important`,
  },
  labelFocused: {
    color: `${palette.info.main} !important`,
    fontWeight: 700,
    '&:hover': {
      fontWeigtht: 700,
    },
  },
  labelRoot: {
    fontSize: '18px',
    '&.MuiInputLabel-filled': {
      paddingLeft: '13px',
      color: palette.info.main,
    },
    '&.MuiFormLabel-filled': {
      fontWeight: '700 !important',
    },
    '&:not(&[data-shrink="true"])': {
      transform: 'translate(8px, 19px) scale(1)',
    },
    '& > i': {
      fontSize: '16px',
    },
  },
  labelDisabled: {
    color: `${colors.grey60} !important`,
    background: colors.grey10,
  },
  root: {
    background: `${palette.common.white} !important`,
    borderRadius: '4px',
    minHeight: '64px',
    height: '64px',
    fontSize: '18px',
    '& .MuiInputAdornment-root': {
      marginRight: '14px',
    },
    color: palette.info.main,
    '& > input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& > input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& > input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& > input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 30px ${palette.common.white} inset`,
    },
    '&.font-face-obfuscate-input:not(.Mui-focused) > input': {
      fontFamily: 'obfuscate !important',
      letterSpacing: '1px',
    },
  },
  disabled: {
    background: `${palette.action.disabledBackground} !important`,
    color: palette.info.main,
  },
  underline: {
    // underline is created here
    '&:before': {
      borderRadius: '4px',
      borderBottom: `${colors.grey10} 2px solid`,
    },
    // underline hover styles
    '&:hover:not(.Mui-error):not(.Mui-focused):before': {
      borderBottomWidth: `3px`,
      borderColor: colors.grey30,
    },
    // error underline styles
    '&.Mui-error:before': {
      borderColor: palette.error.main,
      borderBottomWidth: `3px`,
    },
    // error underline hover styles
    '&.Mui-error:hover:before': {
      borderRadius: '4px',
      borderColor: palette.error.main,
      borderBottomWidth: `3px`,
    },
    '&:after': {
      border: 'none',
    },
    // disabled styles
    '&.Mui-disabled:before': {
      border: 'none',
    },
    '&.Mui-disabled:hover:before': {
      border: 'none',
    },
    '& input': {
      fontWeight: 200,
      paddingLeft: '22px',
      paddingTop: '20px',
    },
  },
  inputFocused: {
    boxShadow: `0 8px 16px ${colors.grey90}29`,
    '&:before': {
      borderBottom: ` 3px ${palette.common.black} solid`,
    },
    '&:hover:before': {
      borderBottom: ` 3px ${palette.common.black} solid`,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `3px ${palette.common.black} solid`,
    },
  },
  helperTextRoot: {
    marginTop: '4px',
    paddingLeft: '0px',
  },
  helperTextError: {
    fontSize: '16px',
    lineHeight: '24px',
    height: 'auto',
    marginLeft: '0px',
  },
}));

type additionalProps = {
  'data-testid'?: string;
  /**
   * Optional property representing the custom name for data used by ForMotiv.
   *
   * @type {string}
   */
  'data-custom-name'?: string;
  preventTrackOnChange?: boolean;
};

const TextInput = (props: TextFieldProps & additionalProps) => {
  const { classes } = styles();
  const meta = props.form.getFieldMeta(props.field.name);
  const { value } = props.field;
  const { error, touched } = meta;
  const [isFocused, setIsFocused] = useState(false);
  const { track } = useSegment();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEvent = { ...e };
    inputEvent.target.value = inputEvent.target.value?.trimStart();
    props.onChange
      ? props.onChange(inputEvent)
      : props.field.onChange(inputEvent);
    // This fixes the issue when error being triggered when copy-pasting
    props.form.setFieldTouched(props.field.name, true, false);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    props.onBlur ? props.onBlur(e) : props.field.onBlur(e);
  };

  useEffect(() => {
    const isTouchedAndBlurred = !isFocused && touched;

    if (isTouchedAndBlurred && error) {
      track('Input Validation Error', {
        input: props.field.value,
        field_name: fieldNameFormatter(props.field.name),
        reason: error,
        eventType: 'Automated System Process',
      });
    } else if (isTouchedAndBlurred && value && !props.preventTrackOnChange) {
      track('Field Information Entered', {
        input: value,
        field_name: fieldNameFormatter(props.field.name),
        eventType: 'User Information Entered',
      });
    }
  }, [isFocused, touched, error, value, props.preventTrackOnChange]);

  const errorEndAdornment = error && touched && (
    <InputAdornment position="end">
      <ErrorIcon title="Error icon" />
    </InputAdornment>
  );
  const inputEndAdornment =
    props?.InputProps?.endAdornment || errorEndAdornment;
  return (
    <TextField
      {...props}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={() => setIsFocused(true)}
      FormHelperTextProps={{
        'aria-live': 'assertive',
        classes: {
          root: classes.helperTextRoot,
          error: classes.helperTextError,
        },
        ...props?.FormHelperTextProps,
      }}
      InputLabelProps={{
        ...(props.InputLabelProps || {}),
        classes: {
          error: classes.labelError,
          focused: classes.labelFocused,
          root: classes.labelRoot,
          disabled: classes.labelDisabled,
        },
        required: false,
      }}
      InputProps={{
        ...(props.InputProps || {}),
        endAdornment: inputEndAdornment,
        classes: {
          disabled: classes.disabled,
          root: classes.root,
          focused: classes.inputFocused,
          underline: classes.underline,
        },
        disabled: props.disabled,
      }}
      inputProps={{
        ...props?.inputProps,
        'aria-required': props.required,
        'data-testid': props['data-testid'],
        'data-custom-name': props['data-custom-name'],
      }}
      variant="filled"
    />
  );
};

export default TextInput;
