import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  DiscountName,
  PropertyAvailableDiscount,
} from '@aaa-ncnu-ie/ez-quote-api-discounts';
import { ContainerProps } from '@mui/material';
import { useActionCreators } from 'api/actions';
import useRequest from 'api/makeRequest';
import {
  AutoAvailableDiscounts,
  DriverDiscount,
  GetAppliedDiscountsResponse,
  GetAvailableDiscountsResponse,
  PropertyAvailableDiscounts,
  SaveAutoDiscountsRequest,
  SavePropertyDiscountsRequest,
} from 'api/schema/discounts.schema';
import { PolicyType } from 'api/schema/insured.schema';
import { sortDiscounts } from 'helpers/discounts';
import { OnHydrated } from 'helpers/form';
import { getNameByPath, getPathByName, PageName } from 'helpers/pages';
import useSegment from 'hooks/useSegment';
import { compact, isBoolean, noop } from 'lodash';
import { MultiPolicyDiscount } from 'components/forms/formTypes';
import { DriverContext } from './DriverContext';
import { ErrorPageContext } from './ErrorPageContext';
import { InsuredContext } from './InsuredContext';

// Note: Attempt to generalize discount schemas.
// However, we might want to introduce more specific
// type guard logic in the future
export type Discounts = Omit<AutoAvailableDiscounts, 'policyDiscounts'> &
  PropertyAvailableDiscounts;

export interface DiscountContextState {
  discounts: Discounts;
  fetchedDiscounts: Discounts;
  appliedDiscounts: GetAppliedDiscountsResponse;
  completedDiscounts: PageName[];
  fetched: boolean;
  appliedFetched: boolean;
}

export type DiscountContextType = {
  discountContextState: DiscountContextState;
  fetchAllDiscounts: () => Promise<GetAvailableDiscountsResponse | undefined>;
  fetchAppliedDiscounts: () => Promise<GetAppliedDiscountsResponse | undefined>;
  updateDriverDiscounts: (
    discount: DriverDiscount | MultiPolicyDiscount,
    driverIds?: string[],
    multiPolicySelected?: boolean,
  ) => Promise<AutoAvailableDiscounts | undefined>;
  updatePolicyDiscounts: (
    discountsToUpdate: PropertyAvailableDiscount[],
  ) => Promise<GetAvailableDiscountsResponse | undefined>;
  navigateDiscounts: (
    from?: PageName,
    historyMethod?: 'push' | 'replace',
  ) => void;
  resetDiscounts: () => void;
  resetCompletedDiscounts: () => void;
  markForReFetch: () => void;
};

const defaultDiscountContextState: DiscountContextState = {
  discounts: {
    driverDiscounts: [],
    vehiclesDiscounts: [],
    policyDiscounts: [],
  },
  fetchedDiscounts: {
    driverDiscounts: [],
    vehiclesDiscounts: [],
    policyDiscounts: [],
  },
  appliedDiscounts: {
    totalDiscountAmount: 0,
    discounts: [],
  },
  completedDiscounts: [],
  fetched: false,
  appliedFetched: false,
};

export const DiscountContext = createContext<DiscountContextType>({
  discountContextState: defaultDiscountContextState,
  fetchAllDiscounts: () => Promise.resolve(undefined),
  fetchAppliedDiscounts: () => Promise.resolve(undefined),
  updateDriverDiscounts: () => Promise.resolve(undefined),
  updatePolicyDiscounts: () => Promise.resolve(undefined),
  navigateDiscounts: () => void 0,
  resetCompletedDiscounts: () => void 0,
  resetDiscounts: noop,
  markForReFetch: () => void 0,
});

const DiscountContextProvider: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  const makeRequest = useRequest();
  const { driverContextState } = useContext(DriverContext);
  const { drivers } = driverContextState;
  const [insuredContextState] = useContext(InsuredContext);
  const {
    actionCreators: { getDiscounts, updateDiscounts, getAppliedDiscounts },
  } = useActionCreators();
  const [discountContextState, setDiscountState] =
    useState<DiscountContextState>(defaultDiscountContextState);
  useContext(ErrorPageContext);

  const discountScreens: PageName[] = [
    'BundleQuestion',
    'ResidenceType',
    'MultiPolicyDiscount',
    'StudentDiscount',
    'SmartDriverDiscount',
    'DefensiveDriverDiscount',
    'TheftAndFireAlarmDiscount',
    'SprinklerDiscount',
    'TravelinkDiscount',
  ];

  const name = getNameByPath(history.location.pathname) as any;
  useEffect(() => {
    // Populate visited pages
    if (discountContextState.completedDiscounts.length == 0) {
      setCompletedDiscounts(
        discountScreens.slice(0, discountScreens.indexOf(name) + 1),
      );
    }

    if (
      !discountContextState.fetched &&
      discountScreens.includes(name) &&
      !(name === 'ResidenceType' && insuredContextState.isBundleFlow) &&
      insuredContextState.fetched
    ) {
      fetchAllDiscounts();
    }
  }, [
    discountContextState.fetched,
    insuredContextState.fetched,
    insuredContextState.isBundleFlow,
    discountScreens.includes(name),
  ]);

  // Hydration on Refresh
  useEffect(() => {
    // This is important to hydrate selected discountees
    // at this stage because it is required for the navigation
    // menu to be populated
    if (discountContextState.fetched) {
      const studentDiscounts = getSelectedDriverIdsForDiscount(
        DriverDiscount.GoodStudent,
      );

      const smartDriverDiscounts = getSelectedDriverIdsForDiscount(
        DriverDiscount.SmartDriver,
      );

      const defensiveDriverDiscounts = getSelectedDriverIdsForDiscount(
        DriverDiscount.DefensiveDriver,
      );

      const travelinkDiscounts = getSelectedDriverIdsForDiscount(
        DriverDiscount.Travelink,
      );

      const theftAlarmType =
        discountContextState?.discounts?.policyDiscounts.find(
          (d) =>
            d.discountName === DiscountName.TheftAlarm && d.selected === true,
        )?.discountAttributes?.alarm;

      const fireAlarmType =
        discountContextState?.discounts?.policyDiscounts.find(
          (d) =>
            d.discountName === DiscountName.FireAlarm && d.selected === true,
        )?.discountAttributes?.alarm;

      const sprinklerValue =
        discountContextState?.discounts?.policyDiscounts.find(
          (d) => d.discountName === DiscountName.ResidentialSprinklers,
        );

      const isSprinkler = sprinklerValue?.selected;

      OnHydrated.publish({
        studentDiscounts,
        smartDriverDiscounts,
        defensiveDriverDiscounts,
        travelinkDiscounts,
        theftAlarmType,
        fireAlarmType,
        isSprinkler,
      });
    }
  }, [discountContextState.fetched]);

  const getDriversForDiscount = (
    discount: DriverDiscount,
  ): AutoAvailableDiscounts['driverDiscounts'][number]['eligibleDrivers'] => {
    return (
      discountContextState.discounts.driverDiscounts.find(
        ({ discountName }) => discountName === discount,
      )?.eligibleDrivers || []
    );
  };

  const getSelectedDriverIdsForDiscount = (
    discount: DriverDiscount,
  ): string[] =>
    getDriversForDiscount(discount)
      .filter(({ selected }) => selected)
      .map(({ driverId }) => driverId);

  const fetchAllDiscounts = (): Promise<
    GetAvailableDiscountsResponse | undefined
  > => {
    return makeRequest(getDiscounts, (discounts) => {
      const driverDiscounts: AutoAvailableDiscounts['driverDiscounts'] =
        (discounts as AutoAvailableDiscounts)?.driverDiscounts ?? [];
      const policyDiscounts: PropertyAvailableDiscounts['policyDiscounts'] =
        (discounts as PropertyAvailableDiscounts)?.policyDiscounts ?? [];
      const vehiclesDiscounts: AutoAvailableDiscounts['vehiclesDiscounts'] =
        (discounts as AutoAvailableDiscounts)?.vehiclesDiscounts ?? [];

      setDiscountState((state) => ({
        ...state,
        fetchedDiscounts: {
          driverDiscounts: [...driverDiscounts],
          policyDiscounts: [...policyDiscounts],
          vehiclesDiscounts: [...vehiclesDiscounts],
        },
        discounts: {
          driverDiscounts,
          policyDiscounts,
          vehiclesDiscounts,
        },
        fetched: true,
      }));
    });
  };

  const resetDiscounts = () => {
    setDiscountState((state) => ({
      ...state,
      fetched: false,
    }));
  };

  const fetchAppliedDiscounts = (): Promise<
    GetAppliedDiscountsResponse | undefined
  > => {
    return makeRequest(
      getAppliedDiscounts,
      ({ totalDiscountAmount, discounts }) => {
        setDiscountState((state) => ({
          ...state,
          appliedDiscounts: {
            totalDiscountAmount,
            discounts: sortDiscounts(discounts),
          },
          appliedFetched: true,
        }));
      },
    );
  };

  const updatePolicyDiscounts = async (
    discountsToUpdate: PropertyAvailableDiscount[],
  ): Promise<GetAvailableDiscountsResponse | undefined> => {
    const updatedPolicyDiscounts = [
      ...discountContextState.discounts.policyDiscounts,
    ].map((discount) => {
      const updatedDiscount: PropertyAvailableDiscount | undefined =
        discountsToUpdate.find(
          (d) =>
            d.discountName === discount.discountName &&
            (d.discountAttributes === undefined ||
              (discount.discountAttributes?.alarm !== undefined &&
                discount.discountAttributes?.alarm ===
                  d.discountAttributes?.alarm) ||
              (discount.discountAttributes?.sprinklers !== undefined &&
                discount.discountAttributes?.sprinklers ===
                  d.discountAttributes.sprinklers)),
        );
      if (updatedDiscount !== undefined) {
        return {
          ...discount,
          selected: updatedDiscount?.selected,
        };
      } else {
        return {
          ...discount,
        };
      }
    });

    await makeRequest(() =>
      updateDiscounts(null, { policyDiscounts: updatedPolicyDiscounts }),
    );

    const updateDiscountsBody: Discounts = {
      ...discountContextState.discounts,
      policyDiscounts: updatedPolicyDiscounts,
    };

    setDiscountState((state) => ({
      ...state,
      discounts: updateDiscountsBody,
    }));

    return;
  };

  const updateDriverDiscounts = async (
    discount: DriverDiscount | MultiPolicyDiscount,
    driverIds?: string[],
    multiPolicySelected?: boolean,
  ): Promise<AutoAvailableDiscounts | undefined> => {
    const nonTargetDriverDiscounts =
      discountContextState.discounts.driverDiscounts.filter(
        ({ discountName }) => discountName !== discount,
      );

    const targetDriverDiscount =
      discountContextState.discounts.driverDiscounts.find(
        ({ discountName }) => discountName! === discount,
      );
    // for api payload
    const targetDriverWithEligibleDriversDiscount = compact([
      targetDriverDiscount && {
        ...targetDriverDiscount,
        eligibleDrivers: drivers
          .filter(({ driverId }) => driverIds?.includes(driverId!))
          .map(({ driverId, firstName, lastName, selected }) => ({
            driverId: driverId!,
            firstName: firstName!,
            lastName: lastName!,
            selected: selected!,
          })),
      },
    ]);

    // for discount state
    const targetDriverWithEligibleDriversDiscountSelected = compact([
      targetDriverDiscount && {
        ...targetDriverDiscount,
        eligibleDrivers: targetDriverDiscount.eligibleDrivers.map(
          ({ driverId, firstName, lastName }) => ({
            driverId: driverId!,
            firstName: firstName!,
            lastName: lastName!,
            selected: driverIds?.includes(driverId!) ? true : false,
          }),
        ),
      },
    ]);

    const updateDiscount: Discounts = {
      ...discountContextState.discounts,
      driverDiscounts: [
        ...nonTargetDriverDiscounts,
        ...targetDriverWithEligibleDriversDiscountSelected,
      ],
    };

    const updateDiscountsBody: Discounts = {
      ...discountContextState.discounts,
      driverDiscounts: [
        ...nonTargetDriverDiscounts,
        ...targetDriverWithEligibleDriversDiscount,
      ],
    };

    const formattedUpdateBody = formatDiscountsIntoPatchRequest(
      updateDiscountsBody,
      !!multiPolicySelected,
      insuredContextState.insured?.product,
    );

    await makeRequest(() => updateDiscounts(null, formattedUpdateBody));

    updateDiscount.policyDiscounts = [
      ...updateDiscount.policyDiscounts.map((discount) => {
        return discount.discountName === 'Multipolicy Discount'
          ? { ...discount, selected: multiPolicySelected }
          : discount;
      }),
    ];

    setDiscountState((state) => ({
      ...state,
      discounts: updateDiscount,
    }));

    return;
  };

  const { track } = useSegment();

  const markForReFetch = () =>
    setDiscountState((state) => ({
      ...state,
      fetched: false,
      appliedFetched: false,
    }));

  const navigateDiscounts: DiscountContextType['navigateDiscounts'] = (
    from,
    historyMethod = 'push',
  ) => {
    const caller =
      from || (getNameByPath(history.location.pathname) as unknown as PageName);
    // make sure caller is added to visited pages
    addCompletedDiscounts(caller);
    const { completedDiscounts } = discountContextState;
    // remove any visited page after caller position
    const callerIndex = completedDiscounts.indexOf(caller);
    const visitedPages =
      callerIndex >= 0
        ? completedDiscounts.slice(0, callerIndex + 1)
        : completedDiscounts;

    const multiPolicyDiscount =
      discountContextState.discounts.policyDiscounts.find(
        (a) => a.discountName === DiscountName.MultiPolicyDiscount,
      );

    const getDiscountRoute = (): PageName => {
      const product = insuredContextState.insured!.product;
      const mpdEligible = multiPolicyDiscount;

      const isCompanionPolicyFound = !mpdEligible;
      // if companion policy search found, ie: no need to ask MPD questions to the customer since the system already knows the user has another policy with csaa.
      // Even though we skip MPD screen users may be eligible for other discounts questions like StudentDiscount,  TheftAlarmDiscount, etc.

      if (isCompanionPolicyFound || discountScreens.includes(caller)) {
        const defensiveDriverEligibleDrivers =
          discountContextState.fetchedDiscounts.driverDiscounts.find(
            (discount) =>
              discount.discountName === DriverDiscount.DefensiveDriver,
          )?.eligibleDrivers || [];

        const goodStudentEligibleDrivers =
          discountContextState.fetchedDiscounts.driverDiscounts.find(
            (discount) => discount.discountName === DriverDiscount.GoodStudent,
          )?.eligibleDrivers || [];

        const smartDriverEligibleDrivers =
          discountContextState.fetchedDiscounts.driverDiscounts.find(
            (discount) => discount.discountName === DriverDiscount.SmartDriver,
          )?.eligibleDrivers || [];

        const theftAlarmDiscount =
          discountContextState.fetchedDiscounts.policyDiscounts.filter(
            (discount) => discount.discountName === DiscountName.TheftAlarm,
          ) || [];

        const fireAlarmDiscount =
          discountContextState.fetchedDiscounts.policyDiscounts.filter(
            (discount) => discount.discountName === DiscountName.FireAlarm,
          ) || [];

        const sprinklerDiscount =
          discountContextState.fetchedDiscounts.policyDiscounts.filter(
            (discount) =>
              discount.discountName === DiscountName.ResidentialSprinklers,
          ) || [];

        const travelinkEligibleDrivers =
          discountContextState.fetchedDiscounts.driverDiscounts.filter(
            (discount) => discount.discountName === 'Travelink Discount',
          ) || [];

        if (
          defensiveDriverEligibleDrivers.length > 0 &&
          !visitedPages.includes('DefensiveDriverDiscount')
        ) {
          return addCompletedDiscounts('DefensiveDriverDiscount');
        } else if (
          goodStudentEligibleDrivers.length > 0 &&
          !visitedPages.includes('StudentDiscount')
        ) {
          return addCompletedDiscounts('StudentDiscount');
        } else if (
          smartDriverEligibleDrivers.length > 0 &&
          !visitedPages.includes('SmartDriverDiscount')
        ) {
          return addCompletedDiscounts('SmartDriverDiscount');
        } else if (
          travelinkEligibleDrivers.length > 0 &&
          !visitedPages.includes('TravelinkDiscount')
        ) {
          return addCompletedDiscounts('TravelinkDiscount');
        } else if (
          (theftAlarmDiscount.length > 0 || fireAlarmDiscount.length > 0) &&
          !visitedPages.includes('TheftAndFireAlarmDiscount')
        ) {
          return addCompletedDiscounts('TheftAndFireAlarmDiscount');
        } else if (
          sprinklerDiscount.length > 0 &&
          !visitedPages.includes('SprinklerDiscount') &&
          product !== PolicyType.RENTERS
        ) {
          return addCompletedDiscounts('SprinklerDiscount');
        } else {
          return 'DiscountSummary';
        }
      } else {
        const isBundleFlow = insuredContextState.isBundleFlow;
        if (product === PolicyType.AUTO) {
          if (isBundleFlow) {
            return 'DiscountSummary';
          } else {
            return addCompletedDiscounts('ResidenceType');
          }
        } else if (
          product === PolicyType.HOMEOWNERS ||
          product === PolicyType.RENTERS ||
          product === PolicyType.CONDO
        ) {
          return mpdEligible
            ? addCompletedDiscounts('MultiPolicyDiscount')
            : 'DiscountSummary';
        } else {
          //TODO: Account for other PolicyTypes in the future
          return 'Entry';
        }
      }
    };

    const PageNameToDiscountMap: Partial<{ [pageName in PageName]: string }> = {
      StudentDiscount: DriverDiscount.GoodStudent,
      SmartDriverDiscount: DriverDiscount.SmartDriver,
      DefensiveDriverDiscount: DriverDiscount.DefensiveDriver,
      TravelinkDiscount: DriverDiscount.Travelink,
      MultiPolicyDiscount: 'Multipolicy Discount',
    };

    const currentDiscountName = PageNameToDiscountMap[caller];
    const currentDiscount = discountContextState.discounts.driverDiscounts.find(
      ({ discountName }) => discountName === currentDiscountName,
    );

    const eligibleDrivers =
      discountContextState.fetchedDiscounts.driverDiscounts.find(
        (discount) => discount.discountName === currentDiscount?.discountName,
      )?.eligibleDrivers || [];

    const selectedDrivers = currentDiscount?.eligibleDrivers?.filter(
      ({ selected }) => !!selected,
    );
    const selected_driver_name = selectedDrivers
      ?.map(({ firstName, lastName }) => `${firstName} ${lastName}`)
      .join(',');
    const eligible_user_name = eligibleDrivers
      ?.map(({ firstName, lastName }) => `${firstName} ${lastName}`)
      .join(',');
    const discount_type = currentDiscount?.discountName || null;
    const count_of_eligible_users = eligibleDrivers?.length || 0;
    const count_of_drivers_selected = selectedDrivers?.length || 0;
    const selection = count_of_drivers_selected === 0 ? 'skip' : 'continue';

    if (discount_type) {
      track('Discounts Selected', {
        event_type: 'Option Selected',
        discount_type,
        count_of_eligible_users,
        count_of_drivers_selected,
        selection,
        selected_driver_name,
        eligible_user_name,
      });
    }

    const path = getPathByName(getDiscountRoute());
    history[historyMethod](path);
  };

  const resetCompletedDiscounts = () => {
    setDiscountState((state) => ({
      ...state,
      completedDiscounts: [],
    }));
    return;
  };

  const setCompletedDiscounts = (pages: PageName[]) => {
    setDiscountState((state) => ({
      ...state,
      completedDiscounts: [...state.completedDiscounts, ...pages],
    }));
  };

  const addCompletedDiscounts = (page: PageName) => {
    !discountContextState.completedDiscounts.includes(page) &&
      setDiscountState((state) => ({
        ...state,
        completedDiscounts: [...state.completedDiscounts, page],
      }));
    return page;
  };

  return (
    <DiscountContext.Provider
      value={{
        discountContextState,
        markForReFetch,
        fetchAllDiscounts,
        fetchAppliedDiscounts,
        updateDriverDiscounts,
        navigateDiscounts,
        resetCompletedDiscounts,
        resetDiscounts,
        updatePolicyDiscounts,
      }}
    >
      {props.children}
    </DiscountContext.Provider>
  );
};

export default DiscountContextProvider;

const formatDiscountsIntoPatchRequest = (
  discounts: Discounts,
  multiPolicySelected?: boolean,
  product?: string,
): Partial<SaveAutoDiscountsRequest & SavePropertyDiscountsRequest> => {
  const formattedDiscounts: Partial<
    SaveAutoDiscountsRequest & SavePropertyDiscountsRequest
  > = {
    policyDiscounts: [],
  };

  if (product === PolicyType.AUTO) {
    formattedDiscounts.driverDiscounts =
      discounts.driverDiscounts.map((driverDiscount) => ({
        discountName: driverDiscount.discountName,
        drivers: driverDiscount.eligibleDrivers.map(
          ({ driverId, selected }) => ({
            driverId,
            selected,
          }),
        ),
      })) || [];

    formattedDiscounts.vehiclesDiscounts =
      discounts.vehiclesDiscounts.map((vehicleDiscount) => ({
        discountName: vehicleDiscount.discountName,
        vehicles: vehicleDiscount.eligibleVehicles
          .map(({ vehicleId, selected }) => ({
            vehicleId,
            selected,
          }))
          .filter(({ selected }) => selected),
      })) || [];
  }

  formattedDiscounts.policyDiscounts = discounts.policyDiscounts.map(
    (props) => ({
      ...props,
      ...(isBoolean(multiPolicySelected) &&
        props.discountName === 'Multipolicy Discount' && {
          selected: multiPolicySelected,
        }),
    }),
  ) as SaveAutoDiscountsRequest['policyDiscounts'];

  return formattedDiscounts;
};
