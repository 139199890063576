import React, { createContext, useState } from 'react';
import { ContainerProps } from '@mui/material';
import { RetrieveQuotesResponse } from 'api/schema/initialize.schema';
import { noop } from 'lodash';
import { RetrieveQuoteDetails } from 'components/forms/formTypes';

export type RetrieveQuoteContextType = {
  setData: (data?: RetrieveQuotesResponse) => void;
  data?: RetrieveQuotesResponse;
  details: RetrieveQuoteDetails;
  setDetails: (details: RetrieveQuoteDetails) => void;
};

export const RetrieveQuoteContext = createContext<RetrieveQuoteContextType>({
  setData: noop,
  details: {
    dateOfBirth: null,
    email: '',
    zipcode: '',
  },
  setDetails: noop,
});

const RetrieveQuoteContextProvider: React.FC<ContainerProps> = (props) => {
  const [data, setData] = useState<RetrieveQuotesResponse>();
  const [details, setDetails] = useState<RetrieveQuoteDetails>({
    dateOfBirth: null,
    email: '',
    zipcode: '',
  });
  return (
    <RetrieveQuoteContext.Provider
      value={{ setData, data, setDetails, details }}
    >
      {props.children}
    </RetrieveQuoteContext.Provider>
  );
};

export default RetrieveQuoteContextProvider;
