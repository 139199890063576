import { getSessionIdKey } from '../utils';

// Ref: https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

export const eventKeys = {
  common: () => {
    const sessionIdKey = getSessionIdKey();
    return [sessionIdKey, 'event'] as const;
  },
  queries: {
    get: () => [...eventKeys.common(), 'get'] as const,
  },
  mutations: {
    sendTrackEvent: () => [...eventKeys.common(), 'sendTrackEvent'] as const,
    sendPageEvent: () => [...eventKeys.common(), 'sendPageEvent'] as const,
  },
};
