import { useHistory } from 'react-router-dom';
import {
  RedirectResponse,
  RetrievedQuote,
  RetrieveQuotesResponse,
} from 'api/schema/initialize.schema';
import { getIndexByPath } from 'helpers/pages';
import { useStartOver } from 'hooks/useStartOver';
import { setCookie } from './cookies';
import { OnStartOver } from './form';
import { setGtmDataLayer } from './gtm';
import { getPathByName } from './pages';

export const HOME = 'H3';
export const SIGNATURE = 'SS';
export const CHOICE = 'AC';

export enum QuoteType {
  Home = 'H3',
  Signature = 'SS',
  Choice = 'AC',
  Select = 'AS',
  Renters = 'H4',
  Condo = 'H6',
}

export const PRODUCT_DESCRIPTIONS: { [index: string]: string } = {
  [QuoteType.Home]: 'HO3 Homeowners',
  [QuoteType.Signature]: 'Auto Signature Series',
  [QuoteType.Choice]: 'Auto Choice Series',
  [QuoteType.Select]: 'CA Select',
  [QuoteType.Renters]: 'HO4 Renters',
};

export const getProductDescription = (quoteNumber: string | undefined) => {
  return quoteNumber ? (quoteNumber?.substring(3, 5) as QuoteType) : '';
};

export const addQuoteInfoToChat = (quoteNumber: string) => {
  const product = getProductDescription(quoteNumber);
  const productDescription = PRODUCT_DESCRIPTIONS[product];
  setGtmDataLayer({
    quote_number: quoteNumber,
    product_description: productDescription || null,
  });
};

export const getQuotePrefix = (type: string) => {
  switch (type) {
    case QuoteType.Home:
      return 'Homeowners Quote';
    case QuoteType.Renters:
      return 'Renters Quote';
    case QuoteType.Condo:
      return 'Condo Quote';
    default:
      return 'Auto Quote';
  }
};

export const useRestoreQuote = () => {
  const history = useHistory();

  return (retrievedQuote: RetrievedQuote, data: RetrieveQuotesResponse) => {
    const { sessionId, authorizationToken, lastPage } = retrievedQuote;
    if (data.maintenance) {
      history.push(getPathByName('Maintenance'));
    } else if (sessionId && authorizationToken) {
      data.chat && setCookie('chatToken', data?.chat);
      setCookie('sessionId', sessionId);
      setCookie('token', `Bearer ${authorizationToken}`);
      setCookie('initiatedQuote', true);
      setCookie('retrieveQuoteFlow', true);
      setCookie('segmentIdSet', true);
      OnStartOver.publish();

      const path = lastPage || '/';
      const tabIndex = getIndexByPath(path);
      const IGNORE_FOR_PAGES = ['/residence-type', '/bundle-question'];
      if (tabIndex && tabIndex > 0 && !IGNORE_FOR_PAGES.includes(path)) {
        setCookie('isBundleQuestionAnswered', true);
      }

      history.push(path);
    }
  };
};

export const useAdcRestoreQuote = () => {
  const history = useHistory();
  const { startOver } = useStartOver();
  return (retrievedQuote: RedirectResponse) => {
    const {
      sessionId,
      authorizationToken,
      lastPageAction,
      melissaToken,
      featureFlags,
      chat,
    } = retrievedQuote;
    if (sessionId && authorizationToken) {
      setCookie('token', `Bearer ${authorizationToken}`);
      setCookie('initiatedQuote', true);
      setCookie('melissaToken', melissaToken);
      setCookie('featureFlags', featureFlags);
      setCookie('chatToken', chat);
      setCookie('segmentIdSet', true);
      setCookie('sessionId', sessionId);

      OnStartOver.publish();
      history.push({
        pathname: lastPageAction || '/',
        search: history?.location?.search,
      });
    } else {
      startOver();
    }
  };
};

export const getQuoteSubType = (
  quoteNumber: string | undefined,
  state: string
) => {
  const quoteType = getProductDescription(quoteNumber);
  const quoteSubType: { [index: string]: string } = {
    [QuoteType.Signature]: 'SS',
    [QuoteType.Choice]: 'CHOICE',
    [QuoteType.Select]: 'SELECT',
    [QuoteType.Home]: 'SS',
    [QuoteType.Renters]: 'SS',
    [QuoteType.Condo]: 'SS',
  };
  return quoteSubType[quoteType];
};
