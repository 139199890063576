import { VehicleDescription } from 'components/forms/formTypes';
import { Source } from '../api/schema/vehicle.schema';

export const getFirstIncompleteVehicle = (
  vehicles: VehicleDescription[] | undefined,
  vehiclesVisited: string[] | undefined,
  currentVehicleId = '',
): VehicleDescription | undefined => {
  return (
    vehicles &&
    vehicles.find(
      (vehicle) =>
        vehicle.vehicleId !== currentVehicleId &&
        vehicle.selected &&
        !vehicle.completed,
    )
  );
};

const sortVehiclesInner = (
  vehicles: VehicleDescription[],
  vehiclesAddedManually: string[],
) => {
  return vehicles.sort((a, b) => {
    if (a.source !== b.source) {
      return a.source === Source.PREFILLED ? -1 : 1;
    } else if (
      vehiclesAddedManually.find((k) => k === a.vehicleId || k === b.vehicleId)
    ) {
      const aIndex = a.vehicleId
        ? vehiclesAddedManually.indexOf(a.vehicleId)
        : -1;
      const bIndex = b.vehicleId
        ? vehiclesAddedManually.indexOf(b.vehicleId)
        : -1;
      if (aIndex !== bIndex) {
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex - bIndex;
      }
    } else if (a.year !== b.year) {
      return parseInt(b.year ?? '0') - parseInt(a.year ?? '0');
    } else if (a.make !== b.make) {
      return a.make ? a.make.localeCompare(b.make!) : 0;
    } else if (a.model !== b.model) {
      return a.model ? a.model.localeCompare(b.model!) : 0;
    }

    return 0;
  });
};

export const sortVehicles = (
  vehicles: VehicleDescription[],
  vehiclesAddedManually?: string[] | null,
) => {
  if (vehiclesAddedManually != null) {
    return sortVehiclesInner(vehicles, vehiclesAddedManually);
  }

  return vehicles.sort((a, b) => {
    if (a.year !== b.year) {
      return parseInt(b.year ?? '0') - parseInt(a.year ?? '0');
    } else if (a.make !== b.make) {
      return a.make ? a.make.localeCompare(b.make!) : 0;
    } else if (a.model !== b.model) {
      return a.model ? a.model.localeCompare(b.model!) : 0;
    }

    return 0;
  });
};
