import { useContext } from 'react';
import { DriverContext } from 'components/contexts/DriverContext';
import { DriverDescription } from 'components/forms/formTypes';
import { useQuery } from 'helpers/routing';
import { sortDrivers } from 'helpers/drivers';

export const useDriverFromRoute = (
  id?: string
): [DriverDescription | undefined, boolean, string] => {
  const {
    getDriversById,
    driverContextState: { fetched },
  } = useContext(DriverContext);

  const query = useQuery();

  const driverId = id || query.get('driverId') || '';
  const drivers: DriverDescription[] | undefined = getDriversById([driverId]);
  const driver = drivers && drivers[0];

  return [driver, fetched, driverId];
};

export const useDriverDlInfo = (): [
  DriverDescription[] | undefined,
  DriverDescription | undefined
] => {
  const { driverContextState } = useContext(DriverContext);
  const incompleteDriverDlInfo: DriverDescription[] | undefined =
    driverContextState?.drivers?.filter(
      (driver) => driver.selected && !driver.completedDl
    );
  const query = useQuery();
  const driverId = query?.get('driverId') || '';
  const driver = driverContextState?.drivers?.find(
    (driver) => driver.driverId === driverId
  );
  return [sortDrivers(incompleteDriverDlInfo) || [], driver];
};
