import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ClubContext } from '../../../components/contexts/ClubContext';
import { InsuredContext } from '../../../components/contexts/InsuredContext';
import { hasText } from '../../../helpers/functions';
import { useActionMakeRequest } from '../../makeRequest';
import { PolicyType } from '../../schema/insured.schema';
import { MetadataResponse } from '../../schema/property.schema';
import { QueryOptions } from '../types';
import { getQueryOptions } from '../utils';
import { propertyKeys } from './propertyKeys';

export const useGetPropertyMetadata = <T = MetadataResponse>(
  options?: QueryOptions<MetadataResponse, unknown, T>,
) => {
  const { actionCall: action } = useActionMakeRequest('getPropertyMetadata');

  const [insuredContextState] = useContext(InsuredContext);
  const { clubState } = useContext(ClubContext);

  const params = {
    state: clubState?.state,
    policyType: insuredContextState?.insured?.product,
  };

  return useQuery({
    queryKey: propertyKeys.queries.getMetadata(params),
    queryFn: () => action(params),
    staleTime: 1000 * 60 * 10, // 10 minutes
    ...getQueryOptions({
      ...(options ?? {}),
      enabled:
        (options?.enabled ?? true) &&
        !!insuredContextState?.fetched &&
        hasText(params.state) &&
        params.policyType != null &&
        [PolicyType.HOMEOWNERS, PolicyType.RENTERS, PolicyType.CONDO]?.includes(
          params.policyType,
        ),
    }),
  });
};
