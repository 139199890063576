import React, { createContext, useContext, useEffect, useState } from 'react';
import { AutoPipCoveragesResponse } from '@aaa-ncnu-ie/ez-quote-api-coverages';
import { ContainerProps } from '@mui/material';
import { useActionCreators } from 'api/actions';
import { PolicyType } from 'api/schema/insured.schema';
import { getCookie } from 'helpers/cookies';
import { ClubContext } from './ClubContext';
import { InsuredContext } from './InsuredContext';

export type CoveragesContextState = {
  pipCoverages: AutoPipCoveragesResponse;
};

export type CoveragesContextType = {
  coveragesContextState: CoveragesContextState;
  showPipCoverages: boolean;
  updateShowPipCoverages: (enable: boolean) => void;
  showPipInsurer: boolean;
  updateShowPipInsurer: (enable: boolean) => void;
};

const defaultCoveragesContextState: CoveragesContextState = {
  pipCoverages: {},
};

export const CoveragesContext = createContext<CoveragesContextType>({
  coveragesContextState: defaultCoveragesContextState,
  showPipCoverages: false,
  updateShowPipCoverages: () => undefined,
  showPipInsurer: false,
  updateShowPipInsurer: () => undefined,
});

const CoveragesContextProvider: React.FC<ContainerProps> = (props) => {
  const [coveragesContextState, setCoveragesContextState] = useState(
    defaultCoveragesContextState,
  );
  const [showPipCoverages, setShowPipCoverages] = useState(false);
  const [showPipInsurer, setShowPipInsurer] = useState(false);
  const [insuredContextState] = useContext(InsuredContext);
  const { clubState } = useContext(ClubContext);
  const {
    actionCreators: { getPipCoverages },
  } = useActionCreators();

  useEffect(() => {
    if (
      insuredContextState.fetched &&
      insuredContextState.insured?.product &&
      clubState.state &&
      insuredContextState.insured.product === PolicyType.AUTO
    ) {
      getPipCoverages().then((data: AutoPipCoveragesResponse) => {
        setCoveragesContextState({
          ...coveragesContextState,
          pipCoverages: data,
        });
        setShowPipCoverages(
          data?.primaryInsurer === undefined &&
            getCookie('featureFlags')?.SHOW_NJ_PIP_INSURANCE_PAGE &&
            clubState.state === 'NJ' &&
            insuredContextState.insured?.product === PolicyType.AUTO,
        );
      });
    }
  }, [
    insuredContextState.fetched,
    insuredContextState.insured?.product,
    clubState.state,
  ]);

  const updateShowPipCoverages = (enable: boolean) => {
    setShowPipCoverages(enable);
  };

  const updateShowPipInsurer = (enable: boolean) => {
   !!clubState?.featureFlags?.pipEnhancedQuestionsEnabled && setShowPipInsurer(enable);
  };

  const providerValue = {
    coveragesContextState,
    showPipCoverages,
    updateShowPipCoverages,
    showPipInsurer,
    updateShowPipInsurer
  };

  return (
    <CoveragesContext.Provider value={providerValue}>
      {props.children}
    </CoveragesContext.Provider>
  );
};

export default CoveragesContextProvider;
