import { DEFAULT_EVENTS, EventsType } from 'react-idle-timer';
import { getCookie } from 'helpers/cookies';
import jwt from 'jwt-decode';

const bearerTokenParsed: string = getCookie('token')
  .replace('Bearer', '')
  .replace(' ', '');

const renewalInfo = bearerTokenParsed
  ? jwt<{ canRenewSession: any }>(bearerTokenParsed)
  : null;

export const isShorterTestingVersion =
  getCookie('featureFlags')?.IDLE_TIMER_TEST_VERSION;

export const sessionTimeoutFeatureEnabled =
  getCookie('featureFlags')?.SESSION_TIMEOUT_FEATURE;

export const canRenew = Boolean(renewalInfo?.canRenewSession);

const ONE_MINUTE = 1000 * 60;
const THREE_MINUTES = ONE_MINUTE * 3;
const FIVE_MINUTES = 1000 * 60 * 5;
const SEVEN_MINUTES = 1000 * 60 * 7;
const TEN_MINUTES = FIVE_MINUTES * 2;
const TWENTY_FIVE_MINUTES = FIVE_MINUTES * 5;
const THIRTY_MINUTES = TEN_MINUTES * 3;

const promptBeforeIdleLength = isShorterTestingVersion
  ? THREE_MINUTES
  : FIVE_MINUTES;
const shortIdleLength = isShorterTestingVersion
  ? FIVE_MINUTES + promptBeforeIdleLength
  : TEN_MINUTES + promptBeforeIdleLength;
const longIdleLength = isShorterTestingVersion
  ? SEVEN_MINUTES + promptBeforeIdleLength
  : TWENTY_FIVE_MINUTES + promptBeforeIdleLength;
const activeTimeAllowedBeforeRenewalLength = THIRTY_MINUTES;

const eventsToExclude: EventsType[] = ['visibilitychange', 'mousemove'];
const eventsToListenFor = DEFAULT_EVENTS.filter(
  (event) => !eventsToExclude.includes(event),
);

export const TimerConfigs = {
  shortIdleLength,
  longIdleLength,
  promptBeforeIdleLength,
  activeTimeAllowedBeforeRenewalLength,
  eventsToListenFor,
};

console.debug(':::: idleTimer: TimerConfigs', TimerConfigs);

export const PageConfigs: any = {
  AdditionalInsured: {
    isLongIdle: true,
  },
  Address: {
    excluded: true,
  },
  CommercialPurposes: {
    isLongIdle: true,
  },
  CompanyInfo: {
    isLongIdle: true,
  },
  Confirmation: {
    excluded: true,
  },
  ConfirmDriverLicense: {
    isLongIdle: true,
  },
  EffectiveDate: {
    isLongIdle: true,
  },
  Evalue: {
    isLongIdle: true,
  },
  Entry: {
    excluded: true,
  },
  ErrorPage: {
    excluded: true,
  },
  GaragingAddressConfirmation: {
    isLongIdle: true,
  },
  Esignature: {
    isLongIdle: true,
  },
  LandlordDetails: {
    isLongIdle: true,
  },
  LongtermDwelling: {
    excluded: true,
  },
  MembershipConfirmation: {
    isLongIdle: true,
  },
  PaperlessConsent: {
    isLongIdle: true,
  },
  MailingAddressConfirmation: {
    isLongIdle: true,
  },
  MembershipIdVerification: {
    isLongIdle: true,
  },
  MembershipDiscountOption: {
    isLongIdle: true,
  },
  MembershipMemberSelectionForm: {
    isLongIdle: true,
  },
  MortgagePageForm: {
    isLongIdle: true,
  },
  PaymentPlans: {
    isLongIdle: true,
  },
  PreviousAddress: {
    excluded: true,
  },
  PropertyOptionalAmenities: {
    isLongIdle: true,
  },
  PurchaseConfirmation: {
    excluded: true,
  },
  TellUsAboutYou: {
    excluded: true,
  },
  ThankYou: {
    excluded: true,
  },
  Thinking: {
    excluded: true,
  },
  VehiclesGaragingAddress: {
    isLongIdle: true,
  },
  ViewSavedQuote: {
    excluded: true,
  },
  VinCollectPageForm: {
    isLongIdle: true,
  },
  Quote: {
    isLongIdle: true,
  },
  QuoteBind: {
    isLongIdle: true,
  },
  PropertyRenovations: {
    isLongIdle: true,
  },
  DwellingDetailsPage: {
    isLongIdle: true,
  },
};
