import { useLocation } from 'react-router-dom';

interface UrlParams {
  source: string;
  clubcode: string;
  state: string;
}

export const getCaseInsensitiveParam = (param: string, search: string) => {
  // Example: ['?test=123', '&check=one']
  const listOfQueries = search.match(/[?|&]([^=]+)=([^&]+)/g);
  if (!listOfQueries) return;

  // Example: [['test', '123'], ['check', 'one']]
  const entries = listOfQueries.map(
    (subsearch) =>
      subsearch.match(/[?|&]([^=, ^&]+)=([^&]+)/)!.slice(1, 3) as [
        string,
        string
      ]
  );

  // Example: '123'
  const result = entries.find(
    ([key]) => key.toLowerCase() === param.toLowerCase()
  )?.[1];

  return result;
};

export const getParamFromCookie = (
  queryParam: any,
  searchValue = '',
  defaultValue: any
) =>
  queryParam[
    Object.keys(queryParam).find(
      (k) => k.toLowerCase() === searchValue.toLowerCase()
    ) || ''
  ] || defaultValue;

export default function useUrlQuery() {
  const location = useLocation<UrlParams>();
  const urlQuery = new URLSearchParams(location.search);
  const getParam = (param = '', caseSensitive = false) =>
    (caseSensitive
      ? urlQuery.get(param)
      : getCaseInsensitiveParam(param, location.search)) || undefined;
  const getAllParams = (param = '') => urlQuery.getAll(param);
  const getUrlQuery = () => urlQuery;

  const adIds = [
    {
      id: 'gclid',
    },
    {
      id: 'maclickid',
    },
    {
      id: 'msclkid',
    },
    {
      id: 'clickid',
    },
    {
      id: 'fbclid',
    },
    {
      id: 'TlyClickID',
    },
    {
      id: 'tdid',
    },
    {
      id: 'promoCodeclickId',
    },
  ];

  const getAdvertisingParam = adIds
    .map(({ id }) => {
      const value = getParam(id);

      if (value)
        return {
          id,
          value,
        };
    })
    .find((param) => param);

  return {
    urlQuery,
    getParam,
    getAllParams,
    getUrlQuery,
    getParamFromCookie,
    getAdvertisingParam,
  };
}
