import React, { createContext, ReactNode, useState } from 'react';

export interface RibbonContextType {
  shouldShowRibbon: boolean;
  toggleRibbon: () => void;
}

const defaultContext: RibbonContextType = {
  shouldShowRibbon: true,
  toggleRibbon: () => {},
};

export const RibbonContext = createContext<RibbonContextType>(defaultContext);

interface RibbonProviderProps {
  children: ReactNode;
}

export const RibbonProvider: React.FC<RibbonProviderProps> = ({ children }) => {
  const [shouldShowRibbon, setShouldShowRibbon] = useState(true);

  const toggleRibbon = () => {
    setShouldShowRibbon((prev) => !prev);
  };

  return (
    <RibbonContext.Provider value={{ shouldShowRibbon, toggleRibbon }}>
      {children}
    </RibbonContext.Provider>
  );
};
