import { Nullable } from './types';

/**
 * Tests whether the value is absent (null or undefined) or not.
 *
 * @param value The value to test.
 */
const isAbsent = (value: unknown): value is null | undefined =>
  value === null || value === undefined;

/**
 * Tests whether the value is present (not null or undefined) or not.
 *
 * @param value The value to test.
 */
const isPresent = <T>(value: T): value is NonNullable<T> => !isAbsent(value);

/**
 * Receives a record with keys that must have non-nullable values, and throws an
 * error in case any of the keys is absent.
 *
 * @param values The record with keys to test.
 */
const assertNotNull = (values: Record<string, unknown>): void => {
  const nullKeys = Object.entries(values)
    .filter(([, value]) => isAbsent(value))
    .map(([key]) => key);

  if (nullKeys.length > 0) {
    throw new Error(
      `Value(s) ${nullKeys.join(', ')} cannot be null or undefined`,
    );
  }
};

/**
 * Gets a value that is required to be present, or throws otherwise.
 *
 * @param value The nullable value to get.
 */
const requireNonNull = <T>(value: T, message?: string): NonNullable<T> => {
  if (isPresent(value)) {
    return value;
  }
  throw new Error(message ?? 'Required value is null or undefined');
};

/**
 * Asserts that a given branch in a function should not be reachable, or throw
 * otherwise. For example:
 *
 *
 * enum YesOrNo {
 *   YES, NO
 * }
 *
 * function example(value: YesOrNo) {
 *   if (value === YesOrNo.YES) {
 *     return true
 *   }
 *
 *   if (value === YesOrNo.NO) {
 *     return false
 *   }
 *
 *   // this will show a compilation error in case a value exists
 *   return assertUnreachable(value)
 * }
 *
 *
 * @param value A value that should not exist.
 */
const assertUnreachable = (value: never): never => {
  throw new Error(`Unreachable code reached with value: ${value}`);
};

/**
 * Turns the `throw` statement into an expression.
 * Its typed result is a non-nullable generic argument to allow this function to
 * be used in coalescing or destructuring (up to the first level only) operations.
 *
 * Examples:
 *
 * const baz1: string = foo?.bar?.baz ?? orThrow(new Error('Could not get baz'))
 * const { baz: baz2 = orThrow(new Error('Could not get baz')) } = foo.bar
 *
 *
 * @param error The error instance to throw.
 */
const orThrow = <R>(error: Error): NonNullable<R> => {
  throw error;
};

/**
 * Tests whether the value is not null or undefined and has non whitespace text.
 *
 * @param value The value to test.
 */
function hasText(text: Nullable<string>): text is string {
  return isPresent(text) && text.trim().length > 0;
}

/**
 * Tests whether the value is not null or undefined and has non whitespace text if it is a string.
 *
 * @param value The value to test.
 */
const isPresentOrHasText = <T>(value: T): value is NonNullable<T> => {
  if (typeof value === 'string') {
    return isPresent(value) && hasText(value);
  }

  return isPresent(value);
};

/**
 * Adds all the elements that aren't falsy of an array separated by the specified separator string.
 * @param separator A string used to separate one element of an array from the next in the resulting String.
 * @param parts An array of values to join.
 */
function joinPresent(separator: string, ...parts: Nullable<string>[]): string {
  return parts.filter(hasText).join(separator);
}

/**
 * Compare two strings ignoring case and accents. 'A' === 'a' and 'E' === 'é'
 * @param a
 * @param b
 */
function isEqualsCaseInsensitive(a: string, b: string) {
  return a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0;
}

/**
 * Build a case-insensitive callback function suited for iterations like Array.find()
 * @param base string for comparison
 */
function compareCaseInsensitive(base: string) {
  return (to: string) => isEqualsCaseInsensitive(base, to);
}

/**
 * Converts the string values in an object to boolean (true for 'true' and false for 'false')
 * @param obj object to be converted
 */
function convertObjectTypes<T extends Record<string, string | boolean>>(
  obj: T,
) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      let convertedValue = value;
      if (value === 'true') convertedValue = true;
      else if (value === 'false') convertedValue = false;
      return [key, convertedValue];
    }),
  );
}

/**
 * Extracts the value of the specified property, deletes it, and assigns it to a new property.
 * Returns the modified object with the new property containing the extracted value.
 */
function reassignProperty(
  obj: any,
  propertyName: string,
  modifiedPropertyName: string,
) {
  const extractedValue = obj?.[propertyName];
  if (extractedValue !== undefined) {
    delete obj[propertyName];
  }
  obj[modifiedPropertyName] = extractedValue;
  return obj;
}

export {
  assertNotNull,
  assertUnreachable,
  compareCaseInsensitive,
  convertObjectTypes,
  hasText,
  isAbsent,
  isEqualsCaseInsensitive,
  isPresent,
  isPresentOrHasText,
  joinPresent,
  orThrow,
  reassignProperty,
  requireNonNull,
};
