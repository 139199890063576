import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getCookie } from 'helpers/cookies';
import { useStartOver } from 'hooks/useStartOver';
import { getPageByPath, getTabIndexByPath } from '../helpers/pages';
import { InsuredContext } from './contexts/InsuredContext';

const Redirect = () => {
  const { pathname } = useLocation();
  const tabIndex = getTabIndexByPath(pathname);
  const [{ isFresh }] = useContext(InsuredContext);

  const { startOver } = useStartOver();

  useEffect(() => {
    if (
      tabIndex !== false &&
      // This will capture both direct route access without sessionId
      // and a refresh before primary insured is created
      isFresh &&
      import.meta.env.DEV === false
    ) {
      startOver();
    }
  }, [isFresh]);

  useEffect(() => {
    const sessionId = getCookie('sessionId');
    const page = getPageByPath(pathname);
    // NOTE: Pages like EntryPage, ErrorPage etc. can be accessed with no sessionId
    const isPageAllowed = page?.tabIndex === false;
    const needsRedirect = !sessionId && !isPageAllowed;
    needsRedirect && startOver();
  }, [pathname]);

  return null;
};

export default Redirect;
