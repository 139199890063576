import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { QueryClient } from '@tanstack/react-query';
import { Remounter } from 'hooks/useStartOver';
import ErrorBoundary from 'components/common/ErrorBoundary';
import RetrieveQuoteContext from 'components/contexts/RetrieveQuoteContext';
import 'typeface-raleway';
import 'typeface-cabin';
import 'typeface-work-sans';
import {
  defaultQueryClientOptions,
  ReactQueryClientProvider,
} from './api/queries/ReactQueryClientProvider';
import theme from './styles/theme';
import App from './App';
import './index.css';
import './styles/raleway-rt.scss';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Ability to opt-in/out of strict can be helpful when it affects performance in dev.
// One known performance issue is related to the navigation menu related
// to interoperability with Material UI.
// (https://github.com/mui-org/material-ui/issues/13394)
const isStrict = JSON.parse(import.meta.env.VITE_STRICT_MODE);
const StrictMode = isStrict ? React.StrictMode : React.Fragment;

console.warn = (message: string) =>
  message.includes('Formik context is undefined') ? null : message;
// ^ this warning is unavoidable with current architecture, and creating too much noise in the console

const queryClient = new QueryClient(defaultQueryClientOptions);
const root = createRoot(document.getElementById('root') as Element);
root.render(
  <StrictMode>
    <ErrorBoundary>
      <ReactQueryClientProvider queryClient={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              <RetrieveQuoteContext>
                <Remounter>
                  <App />
                </Remounter>
              </RetrieveQuoteContext>
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </ReactQueryClientProvider>
    </ErrorBoundary>
  </StrictMode>
);
