import { useContext, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getCookie, setCookie } from 'helpers/cookies';
import { Action, Location } from 'history';
import { BackHandlerContext } from '../components/contexts/BackHandlerContext';
import { setGtmDataLayer } from '../helpers/gtm';
import { getIndexByName, getIndexByPath } from '../helpers/pages';

export const useBackForwardHandler = () => {
  const { block } = useHistory();
  const [lastLocationIndex, setLastLocationIndex] = useState<number>(0);
  const [launchProactiveMsg, setLaunchProactiveMsg] = useState<boolean>(false);
  const { handlers } = useContext(BackHandlerContext);

  useLayoutEffect(() => {
    // Preventing Browser Forward button from navigating to next page
    // Ref: https://thewebdev.info/2022/03/07/how-to-intercept-and-handle-browsers-back-button-in-react-router/
    //      https://github.com/remix-run/history/blob/main/docs/blocking-transitions.md
    return block((newLocation: Location, action: Action) => {
      const currentLocationKey = newLocation?.key ?? 'initial';
      const locationKeys: string[] = getCookie('locationKeys')
        ? getCookie('locationKeys').split('|')
        : [];
      const visitedPages: string[] = getCookie('visitedPages')
        ? getCookie('visitedPages').split('|')
        : [];
      const isVisitedPage = visitedPages.includes(newLocation?.pathname);
      const lastPage = visitedPages[visitedPages.length - 1];

      if (
        getIndexByPath(lastPage) >= getIndexByName('EffectiveDate') &&
        isVisitedPage &&
        !launchProactiveMsg
      ) {
        //Display Proactive Chat Message When I navigate back from any screen in the bind flow starting at the effective date screen.
        setGtmDataLayer({
          launchProactiveMsg: true,
        });
        setLaunchProactiveMsg(true);
      }

      if (action === 'PUSH') {
        setLastLocationIndex(locationKeys.length);
        setCookie(
          'locationKeys',
          [...locationKeys, currentLocationKey].join('|')
        );
      }

      if (action === 'REPLACE') {
        const tempLocationKeys = Array.from(locationKeys);
        tempLocationKeys[tempLocationKeys.length - 1] = currentLocationKey;
        setCookie('locationKeys', tempLocationKeys.join('|'));
      }

      if (action === 'POP') {
        const currentLocationIndex = locationKeys.findIndex(
          (key, index) =>
            key === currentLocationKey && index !== lastLocationIndex
        );
        const isForwardNavigation = currentLocationIndex > lastLocationIndex;

        if (isForwardNavigation) {
          return false;
        }

        const canGoBack =
          handlers.length > 0
            ? handlers.every((handler) => handler(newLocation, action))
            : true;

        if (canGoBack) {
          setLastLocationIndex(currentLocationIndex);
        } else {
          return false;
        }
      }

      setCookie(
        'visitedPages',
        [...visitedPages, newLocation?.pathname].join('|')
      );
    });
  }, [handlers, block, lastLocationIndex]);
};
