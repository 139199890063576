import { RelationshipToPniCd } from '@aaa-ncnu-ie/ez-quote-session-types';
import {
  Driver as APIResponseDriver,
  MetadataResponse,
  Source,
} from 'api/schema/driver.schema';
import { InsuredContextState } from 'components/contexts/InsuredContext';
import { DriverDescription } from 'components/forms/formTypes';

export const getFirstIncompleteDriver = (
  drivers: DriverDescription[] | undefined,
  driversVisited: (string | undefined)[],
  currentDriverId = '',
): DriverDescription | undefined =>
  drivers
    ?.sort((a, b) => {
      if (getIsSelf(a) || getIsSelf(b)) {
        return getIsSelf(a) ? -1 : 1;
      } else if (a.source !== b.source) {
        return a.source === Source.PREFILLED ? -1 : 1;
      } else {
        return (
          (a.birthDate?.valueOf() || 0) - (b.birthDate?.valueOf() || 0) ||
          (a.firstName?.localeCompare(b.firstName ?? '') ?? 0) ||
          (a.lastName?.localeCompare(b.lastName ?? '') ?? 0)
        );
      }
    })
    ?.find(
      (driver) =>
        driver.driverId !== currentDriverId &&
        driver.selected &&
        !driver.completed,
    );

export const getIsSelf = (driver: DriverDescription | APIResponseDriver) =>
  driver.relationship?.toLowerCase() === 'self';

export const sortDrivers = (drivers: DriverDescription[]) => {
  return drivers.sort((a, b) => {
    if (getIsSelf(a) || getIsSelf(b)) {
      return getIsSelf(a) ? -1 : 1;
    } else if (a.source !== b.source) {
      return a.source === Source.PREFILLED ? -1 : 1;
    } else {
      return (
        (a.birthDate?.valueOf() || 0) - (b.birthDate?.valueOf() || 0) ||
        (a.firstName?.localeCompare(b.firstName ?? '') ?? 0) ||
        (a.lastName?.localeCompare(b.lastName ?? '') ?? 0)
      );
    }
  });
};

export const checkNeedsSpouseAdded = (
  insured: InsuredContextState['insured'],
  unfilteredDrivers: DriverDescription[] = [],
  driverIds?: string[],
) => {
  const isThereASpouse =
    insured?.maritalStatus && ['M', 'C'].includes(insured?.maritalStatus);
  if (!isThereASpouse) return false;

  const drivers = !driverIds
    ? unfilteredDrivers
    : unfilteredDrivers.filter((d) => driverIds.includes(d.driverId!));

  const spouseAdded = drivers.some((driver) => {
    const adjustedRelationship = driver.relationship?.split(' ')?.join('');
    return (
      (driver.selected &&
        adjustedRelationship === RelationshipToPniCd.SPOUSE) ||
      adjustedRelationship === RelationshipToPniCd.DOMESTIC_PARTNER
    );
  });
  if (!spouseAdded) return true;
};

export const isMaritalStatusMismatch = (
  insured: InsuredContextState['insured'],
  unfilteredDrivers: DriverDescription[] = [],
  driverIds?: string[],
) => {
  const drivers = !driverIds
    ? unfilteredDrivers
    : unfilteredDrivers.filter((d) => driverIds.includes(d.driverId!));

  const spouseAdded = drivers?.some((driver) => {
    const adjustedRelationship = driver.relationship?.split(' ')?.join('');
    return (
      (driver.selected &&
        adjustedRelationship === RelationshipToPniCd.SPOUSE &&
        insured?.maritalStatus !== 'M') ||
      (adjustedRelationship === RelationshipToPniCd.DOMESTIC_PARTNER &&
        insured?.maritalStatus !== 'C')
    );
  });
  return spouseAdded;
};

export const isDriverMaritalStatusMismatch = (
  insured: InsuredContextState['insured'],
  adjustedRelationship: RelationshipToPniCd,
) =>
  (adjustedRelationship === RelationshipToPniCd.SPOUSE &&
    insured?.maritalStatus !== 'M') ||
  (adjustedRelationship === RelationshipToPniCd.DOMESTIC_PARTNER &&
    insured?.maritalStatus !== 'C');

export const getRelationshipLabel = (
  relationshipValue: RelationshipToPniCd | undefined | null,
  metadata: MetadataResponse | undefined,
) => {
  return metadata?.relationshipToPNI?.find(
    (item) => item.value === relationshipValue,
  )?.label;
};

export const getRelationshipValue = (
  relationshipLabel: RelationshipToPniCd | undefined | null,
  metadata: MetadataResponse | undefined,
) => {
  const value = metadata?.relationshipToPNI?.find(
    (item) => item.label === relationshipLabel,
  )?.value;

  return value != null ? (value as RelationshipToPniCd) : undefined;
};
