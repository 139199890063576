import { MelissaExpressFreeFormResponse } from '@aaa-ncnu-ie/ez-quote-api-util';
const ADDRESS_REGEX = /^([^-]*?)(?:-)?(?:-\d{1,4})?$/i;
const POBOX_REGEX = /^\s*P(ost|ostal)?([\s|.]*O(ffice)?)?([\s|.]*Box)\b/i;

export const allStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
] as const;

export const supportedStates = [
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'ID',
  'IN',
  'KS',
  'KY',
  'MD',
  'MT',
  'NJ',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'SD',
  'UT',
  'VA',
  'WV',
  'WY',
] as const;

export const parseAddress = (address: string) => {
  while (address.indexOf(' ,') > -1) {
    address = address.replace(' ,', ',');
  }
  const [_, addressLine1 = '', city = '', stateProvCd = '', postalCode = ''] =
    address.match(/^(.+), (.+), (.+) (\d+)(-\d+)?$/i) || [];

  return {
    addressLine1,
    city,
    stateProvCd,
    postalCode,
  };
};

type Address = {
  addressLine1: string;
  apartment: string;
  city: string;
  stateProvCd: string;
  postalCode: string;
};

export const formatAddress = (address: Partial<Address>) => {
  const { addressLine1, city, stateProvCd, postalCode, apartment } = address;

  return `${addressLine1}${
    apartment ? `, ${apartment}` : ''
  }, ${city}, ${stateProvCd} ${postalCode}`;
};

export const parsePostalCode = (address: string) => {
  const [postalCode = ''] = address.match(/\d+$/) || [];

  return {
    postalCode,
  };
};

export const formatPoBox = (v: string) =>
  v.includes('PO Box') ? v : `PO Box ${v}`;

export const parsePoBox = (address: string) =>
  address.replace(POBOX_REGEX, '').trim();

export const isPOBox = (address: string) => POBOX_REGEX.test(address);

export type CustomAddress = {
  address: string;
  state: string;
  zipcode: string;
  county?: string;
};

export const normalizeAddressServiceResponse = ({
  Results,
}: MelissaExpressFreeFormResponse): CustomAddress[] => {
  const mappedAddresses = Results.map((Address) => {
    const { AddressLine1, City, State, PostalCode, Extras } = Address.Address;
    const postalCode = PostalCode?.substring(0, 5);
    const address = `${AddressLine1}, ${City}, ${State} ${postalCode}`;
    const matches = address.match(ADDRESS_REGEX);
    const county = Extras?.CountyName;
    if (address && matches && matches[1]) {
      return {
        address: matches[1],
        county,
        state: State,
        zipcode: postalCode,
      };
    }
  })
    .filter((address) => address !== undefined)
    .map((address) => address!); // NOTE: To avoid casting

  return mappedAddresses.filter(
    (result, idx, self) =>
      idx ===
      self.findIndex(
        (s) =>
          s.address === result.address &&
          s.state === result.state &&
          s.zipcode === result.zipcode
      )
  );
};
