type LocalStorageKeys = 'myq_previous_session_id';

export const getLocalStorageItem = (key: LocalStorageKeys): string | null => {
  return localStorage.getItem(key);
};

export const setLocalStorageItem = (
  key: LocalStorageKeys,
  value: string,
): void => {
  localStorage.setItem(key, value);
};
