import { PolicyType, ResidenceType } from 'api/schema/insured.schema';

export const getProductType = (policy?: string): PolicyType => {
  switch (policy) {
    case PolicyType.AUTO:
      return PolicyType.AUTO;

    case PolicyType.HOMEOWNERS:
      return PolicyType.HOMEOWNERS;

    case PolicyType.RENTERS:
      return PolicyType.RENTERS;

    case PolicyType.CONDO:
      return PolicyType.CONDO;

    default:
      return PolicyType.AUTO;
  }
};

const propertyPolicyTypes = [
  PolicyType.HOMEOWNERS,
  PolicyType.RENTERS,
  PolicyType.CONDO,
  PolicyType.LANDLORD,
];

export const getIsProperty = (policyType?: PolicyType) => {
  if (policyType == null) {
    return false;
  }

  return propertyPolicyTypes.includes(policyType);
};

export const mapResidenceToPolicy = (
  residenceType: ResidenceType | undefined,
) => {
  switch (residenceType) {
    case ResidenceType.OWNHOME:
      return PolicyType.HOMEOWNERS;
    case ResidenceType.OWNCONDO:
      return PolicyType.CONDO;
    case ResidenceType.RENT:
      return PolicyType.RENTERS;
    default:
      return undefined;
  }
};

export const mapPolicyToResidence = (policyType: PolicyType | undefined) => {
  switch (policyType) {
    case PolicyType.HOMEOWNERS:
      return ResidenceType.OWNHOME;
    case PolicyType.CONDO:
      return ResidenceType.OWNCONDO;
    case PolicyType.RENTERS:
      return ResidenceType.RENT;
    default:
      return undefined;
  }
};

export const isBundleEnabledForResidenceType = (
  bundleProducts: { [type in PolicyType]?: boolean } | undefined,
  residenceType: ResidenceType | undefined,
): boolean => {
  if (!bundleProducts || !residenceType) {
    return false;
  }

  switch (residenceType) {
    case ResidenceType.OWNHOME:
      return !!bundleProducts[PolicyType.HOMEOWNERS];
    case ResidenceType.RENT:
      return !!bundleProducts[PolicyType.RENTERS];
    case ResidenceType.OWNCONDO:
      return !!bundleProducts[PolicyType.CONDO];
    default:
      return false;
  }
};

export const isEligibleResidenceType = (
  residenceType: ResidenceType | undefined,
): boolean => {
  return (
    !!residenceType &&
    [
      ResidenceType.OWNHOME,
      ResidenceType.OWNCONDO,
      ResidenceType.RENT,
    ].includes(residenceType)
  );
};

export const getBundlePolicyTypeFromParam = (
  param: string | undefined,
): PolicyType | undefined => {
  switch (param) {
    case PolicyType.HOMEOWNERS:
      return PolicyType.HOMEOWNERS;

    case PolicyType.RENTERS:
      return PolicyType.RENTERS;

    case PolicyType.CONDO:
      return PolicyType.CONDO;

    default:
      return undefined;
  }
};
