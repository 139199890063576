import React from 'react';

// This is the easiest way to preload fonts in our current setup
// This solves the issue with the fallback twitch associated with dynamically loaded fonts
// NOTE: Feel free to suggest a better approach
export const FontLoader = () => (
  <div
    aria-hidden="true"
    style={{ position: 'absolute', opacity: 0, zIndex: -999 }}
  >
    <p
      style={{ background: 'white', fontFamily: 'RT Raleway', fontWeight: 400 }}
    >
      Raleway 400
    </p>
    <p
      style={{ background: 'white', fontFamily: 'RT Raleway', fontWeight: 500 }}
    >
      Raleway 500
    </p>
    <p
      style={{ background: 'white', fontFamily: 'RT Raleway', fontWeight: 700 }}
    >
      Raleway 700
    </p>
    <p style={{ background: 'white', fontFamily: 'Raleway', fontWeight: 400 }}>
      Raleway 400
    </p>
    <p style={{ background: 'white', fontFamily: 'Raleway', fontWeight: 500 }}>
      Raleway 500
    </p>
    <p style={{ background: 'white', fontFamily: 'Raleway', fontWeight: 700 }}>
      Raleway 700
    </p>
    <p style={{ background: 'white', fontFamily: 'Cabin', fontWeight: 400 }}>
      Cabin 400
    </p>
    <p style={{ background: 'white', fontFamily: 'Cabin', fontWeight: 500 }}>
      Cabin 500
    </p>
    <p style={{ background: 'white', fontFamily: 'Cabin', fontWeight: 600 }}>
      Cabin 600
    </p>
    <p style={{ background: 'white', fontFamily: 'Cabin', fontWeight: 700 }}>
      Cabin 700
    </p>
    <p style={{ background: 'white', fontFamily: 'obfuscate' }}>
      Obfuscated Font
    </p>
  </div>
);
