import React, { createContext } from 'react';
import { ContainerProps } from '@mui/material';
import useModal from '../../hooks/useModal';
import Modal from '../modals/SimpleModal';

export type ModalContent = {
  key: string;
  heading: string;
  content?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
};

export type ModalContext = {
  modal: boolean;
  handleModal: (o?: ModalContent) => void;
  modalContent: ModalContent;
};

const defaultContext: ModalContext = {
  modal: false,
  handleModal: () => {
    /**/
  },
  modalContent: {
    key: '',
    heading: '',
    content: '',
    onCancel: () => {
      /**/
    },
    onConfirm: () => {
      /**/
    },
    cancelButtonText: '',
    confirmButtonText: '',
  },
};

export const ModalContext = createContext<ModalContext>(defaultContext);

const ModalContextProvider: React.FC<ContainerProps> = (props) => {
  const { modal, handleModal, modalContent } = useModal();
  const content = modalContent as ModalContent;

  return (
    <ModalContext.Provider
      value={{ modal, handleModal, modalContent: content }}
    >
      <Modal />

      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
