// Declare GTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const setGtmDataLayer = (data: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const resetGtmDataLayer = () => {
  setGtmDataLayer({
    chatEnabled: false,
    first_driver_name: undefined,
    club_code: undefined,
    state: undefined,
    zipcode: undefined,
    promo_code: undefined,
    source: undefined,
    policy_type: undefined,
    botId: undefined,
    clientId: undefined,
    token: undefined,
    maintenance: undefined,
    sessionId: undefined,
    support_phone_number: undefined,
    quote_number: undefined,
    product_description: undefined,
    utm_campaign: undefined,
    utm_source: undefined,
    utm_medium: undefined,
    dmlp_referrer: undefined,
    bind_offered: undefined,
    getStartedBtn: undefined,
    continueBtn: undefined,
    premium: undefined,
    membership_premium: undefined,
    quote_payment_amount: undefined,
    total_payment_amount: undefined,
    policy_number: undefined,
    insured: undefined,
  });
};
