import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyType } from 'api/schema/insured.schema';
import * as yup from 'yup';
import { ClubContext } from 'components/contexts/ClubContext';
import { InsuredContext } from 'components/contexts/InsuredContext';
import validations from '../components/validations';

export const useValidation = (pageName: string, policyType?: PolicyType) => {
  const [validationSchema, setValidationSchema] = useState<yup.ObjectSchema>();
  const { t } = useTranslation();
  const [insuredState] = useContext(InsuredContext);
  const { clubState } = useContext(ClubContext);

  useEffect(() => {
    setValidationSchema(
      validations(t, insuredState, clubState, policyType)[pageName],
    );
  }, [pageName, insuredState, clubState]);

  return validationSchema;
};
