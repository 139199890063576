import React from 'react';
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const defaultQueryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20, //default to 20 seconds
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      useErrorBoundary: true,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
};

export const ReactQueryClientProvider = ({
  queryClient,
  children,
}: // eslint-disable-next-line @typescript-eslint/ban-types
React.PropsWithChildren<{
  queryClient: QueryClient;
}>) => {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {showDevtools && (
        <React.Suspense fallback={null}>
          <ReactQueryDevtools initialIsOpen={true} />
        </React.Suspense>
      )}
    </QueryClientProvider>
  );
};
