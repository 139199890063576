import React, { ReactElement } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogProps,
  Grid,
  IconButton,
  PaperProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import useSegment from 'hooks/useSegment';
import { makeStyles } from 'tss-react/mui';
import { getAttribute } from '../../helpers/testing';
import { colors, getZIndexForPreference } from '../../styles/theme';

const useStyles = makeStyles()(({ breakpoints }: Theme) => ({
  title: {
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: 700,
    textAlign: 'center',
    margin: '0 32px 16px',
    [breakpoints.only('mobile')]: {
      fontSize: '24px',
      lineHeight: '32px',
      maxWidth: '300px',
    },
  },
  paper: {
    alignItems: 'center',
    [breakpoints.up('tablet')]: {
      maxWidth: 'unset',
      width: '480px',
    },
  },
  root: {
    '& > .MuiBackdrop-root': {
      position: 'fixed',
      background: `rgba(171, 175, 191, 0.8)`,
      backdropFilter: `blur(16px)`,
      opacity: '0.9 !important',
    },
    '& .MuiPaper-root.MuiDialog-paper': {
      background: colors.grey5,
      borderRadius: '16px',
    },
    '& .MuiDialog-paperFullWidth': {
      // fullWidth={true} - maximize mobile size
      margin: 0,
      width: `calc(100% - 16px)`,
    },
  },
  iconGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px',
  },
  icons: {
    fontSize: '16px',
    color: colors.black,
    zIndex: getZIndexForPreference(-1),
  },
  back: {
    color: colors.grey70,
    fontSize: '18px',
    padding: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '12px',
      width: '8px',
      height: '8px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowX: 'hidden',
  },
}));

type ModalProps = {
  title: string | ReactElement;
  children: React.ReactNode;
  open: boolean;
  onClose: any;
  testId: string;
  modalName: string;
  onGoBack?: () => void;
};

const Modal = ({
  title,
  children,
  open,
  onClose,
  testId,
  onGoBack,
  modalName,
  PaperProps,
  ...rest
}: ModalProps & Omit<DialogProps, 'title'>) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { track } = useSegment();

  useEffect(() => {
    // NOTE: This allows to reset focus so that keyboard navigation
    // can continue from the top of the screen (instead of from the bottom like
    // it is currently)
    if (!open) {
      const container = document.getElementById('landing-page-container');
      container && container.focus();
    }
  }, [open]);

  return (
    <Dialog
      aria-labelledby={'csaa-modal-title'}
      aria-describedby={'csaa-modal-title'}
      PaperProps={
        {
          ...getAttribute(testId, 'dialog'),
          ...(PaperProps ?? {}),
        } as PaperProps
      }
      style={{
        zIndex: getZIndexForPreference(2),
      }}
      {...rest}
      classes={{ root: classes.root, paper: classes.paper }}
      open={open}
      onClose={onClose}
      TransitionProps={{ role: 'presentation' } as TransitionProps}
    >
      <>
        <Grid container className={classes.iconGroup}>
          {onGoBack && (
            <Typography
              className={classes.back}
              {...getAttribute(testId, 'go-back')}
              aria-label="Go Back"
              tabIndex={0}
              onClick={onGoBack}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onGoBack && onGoBack();
                }
              }}
            >
              <ArrowBackIcon className={classes.icons} /> {t('modal.back')}
            </Typography>
          )}
          <div></div>
          <IconButton
            disableTouchRipple
            {...getAttribute(testId, 'close-icon')}
            aria-label="close"
            onClick={() => {
              track('Modal Closed', {
                eventType: 'Link Accessed',
                modal_name: modalName,
              });

              onClose();
            }}
            size="large"
          >
            <CloseIcon className={classes.icons} />
          </IconButton>
        </Grid>
        <Typography
          className={classes.title}
          variant="h2"
          id="csaa-modal-title"
        >
          {title}
        </Typography>
        <div className={classes.container}>{children}</div>
      </>
    </Dialog>
  );
};

export default Modal;
