import moment from 'moment';
import { Optional } from './types';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

export const serverDateFormatter = (date: string) => {
  if (!date) return null;

  const d = date.split('T')[0].split('-');
  return new Date(`${d[1]}/${d[2]}/${d[0]}`);
};

export const fieldNameFormatter = (fieldName: string) => {
  const list = fieldName.split('.');
  return list[list.length - 1];
};

export const effectiveDateFormatter = (effectiveDate: string) =>
  moment(effectiveDate).format('YYYY-MM-DD');

export const expirationDateFormatter = (expirationDate: string) =>
  expirationDate
    .replace(
      /^([1-9]\/|[2-9])$/g,
      '0$1/', // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      '$1/', // 11 > 11/
    )
    .replace(
      /^([0-1])([3-9])$/g,
      '0$1/$2', // 13 > 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      '$1/$2', // 141 > 01/41
    )
    .replace(
      /^([0]+)\/|[0]+$/g,
      '0', // 0/ > 0 and 00 > 0
    )
    .replace(
      /[^\d\/]|^[\/]*$/g, //eslint-disable-line
      '', // To allow only digits and `/`
    )
    .replace(
      /\/\//g,
      '/', // Prevent entering more than 1 `/`
    );

export const exipryDateFormatter = (expiryDate: string) => {
  const dt = expiryDate.split('/');
  const mm = dt[0];
  const yy = dt[1];

  return `20${yy}-${mm}-01`;
};

export const creditCardNumberFormatter = (
  cardNumber: string,
  { mask = false } = {},
) => {
  const isValid = !cardNumber.match(/[^0-9\s-]/);

  if (!isValid) {
    return cardNumber;
  }

  const sanitized = cardNumber.replace(/[^0-9]/g, '');

  const prettifiedCardNumber = (() => {
    // if length is 16, it's probably a mastercard/visa/discover
    if (sanitized.length === 16) {
      // format as "1111 2222 3333 4444"
      return sanitized.replace(/([0-9]{4})/g, '$1 ').trim();
    }

    // if length is 15, it's probably AMEX
    if (sanitized.length === 15) {
      // format as "1111 222222 33333"
      return sanitized
        .replace(/([0-9]{4})([0-9]{6})([0-9]{5})/g, '$1 $2 $3')
        .trim();
    }

    // else, we dont know, so just return the card number without prettifying
    return sanitized.trim();
  })();

  if (mask) {
    const last4digits = prettifiedCardNumber.substring(
      prettifiedCardNumber.length - 4,
    );

    const rest = prettifiedCardNumber
      .substring(0, prettifiedCardNumber.length - 4)
      .replace(/[0-9]/g, '*');

    return `${rest}${last4digits}`;
  }

  return prettifiedCardNumber;
};

export const maskedAccountNumberFormatter = (accountNumber: string) => {
  const sanitized = accountNumber.trim();

  const last4digits = sanitized.substring(sanitized.length - 4);

  const rest = sanitized
    .substring(0, sanitized.length - 4)
    .replace(/[0-9]/g, '*');

  return `${rest}${last4digits}`;
};

export const removeCurrencyFormat = (currency: Optional<string>) =>
  currency?.replace(/[^0-9.-]+/g, '');

export const formatCurrencyToNumberOptional = (currency: Optional<string>) =>
  currency != null ? Number(removeCurrencyFormat(currency)) : undefined;

export const formatCurrencyToNumber = (currency: string) =>
  Number(removeCurrencyFormat(currency));

export const maskDl = (dl: string) => dl.slice(-4)?.padStart(dl.length, '*');

/**
 * Removes spaces and dashes from a given string.
 *
 * @param {string} str - the input string from which spaces and dashes will be removed
 * @return {string} the input string without any space or dash
 */
export const removeSpacesAndDashes = (
  str: string | undefined,
): string | undefined => {
  return str?.replace(/\s|-/gi, '');
};
