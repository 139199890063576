import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getCookie } from 'helpers/cookies';
import { getPathByName } from 'helpers/pages';
import { useStartOver } from 'hooks/useStartOver';
import { ErrorPageContext } from 'components/contexts/ErrorPageContext';
import { ModalContext } from 'components/contexts/ModalContext';
import { ActionCreators, useActionCreators } from './actions';
import { FetchError } from './responseTypes';

export const DEFAULT_AGENT_ERROR_CODES = [
  'QUOTE_INELIGIBLE',
  'PRI_INS_RESTRICTION',
  'PRODUCT_DISABLED',
];

type ErrorRangesType = {
  agent?: [number, number][];
  error?: [number, number][];
};

type ErrorCodesType = {
  agent?: string[];
  error?: string[];
};

export type HandleApiErrorParams = {
  rawError: unknown;
  errorRanges?: ErrorRangesType;
  errorCodes?: ErrorCodesType;
  shouldRedirectOnError?: boolean;
};

export const useHandleApiError = () => {
  const history = useHistory();
  const { handleModal } = useContext(ModalContext);
  const [, setErrorPageState] = useContext(ErrorPageContext);
  const { t } = useTranslation('', { useSuspense: false });
  const { startOver } = useStartOver();

  const handleApiError = useCallback(
    ({
      rawError,
      errorRanges = {},
      errorCodes = {},
      shouldRedirectOnError = true,
    }: HandleApiErrorParams) => {
      const err = rawError as FetchError;
      let parsedError;
      try {
        parsedError = JSON.parse(err.message);
      } catch (e) {
        console.error('Error parsing error message: ', e);
      }
      const parsedErrorCode = parsedError?.errorCode;
      const additionalInfo = parsedError?.additionalInfo;
      const isBindFlow = getCookie('isBindFlow');
      if (err.status === 504) {
        setErrorPageState({
          reason: 'agent',
          errorCode: parsedErrorCode || 'TIMEOUT',
          pasErrorCode:
            additionalInfo?.pasErrorCode ||
            additionalInfo?.errorCode ||
            additionalInfo,
          rawErrorMessage: err.message,
          errorCodes: additionalInfo?.errorCodes,
        });
        history.push({
          pathname: getPathByName('ErrorPage'),
          search: `?reason=timeout${isBindFlow && '&flow=bind'}`,
        });
        return;
      }

      if ([403].includes(err.status)) {
        handleModal({
          key: 'session-timeout-modal',
          heading: t('sessiontimeoutmodal.title'),
          onCancel: startOver,
          cancelButtonText: t('sessiontimeoutmodal.cancelButtonText'),
        });
        return;
      }

      const isInAgentRange = errorRanges.agent
        ?.map((r) => err.status >= r[0] && err.status < r[1])
        .some((v) => v);

      const isAgentErrorCode = (errorCodes.agent || DEFAULT_AGENT_ERROR_CODES)
        ?.map((c) => err.message.includes(c))
        .some((v) => v);

      const isInErrorRange = (errorRanges.error || [[300, 600]])
        .map((r) => err.status >= r[0] && err.status < r[1])
        .some((v) => v);

      const isErrorErrorCode = errorCodes.error
        ?.map((c) => err.message.includes(c))
        .some((v) => v);

      const isAgent = (isInAgentRange || isAgentErrorCode) && 'agent';
      const isError = (isInErrorRange || isErrorErrorCode) && 'error';
      const reason = isAgent || isError || 'error';

      if (shouldRedirectOnError && (isAgent || isError)) {
        const errorCode =
          parsedErrorCode ||
          `503 or other API error ${err.status ? `(${err.status}) ` : ''}received`;
        setErrorPageState({
          reason,
          errorCode,
          pasErrorCode:
            additionalInfo?.pasErrorCode ||
            additionalInfo?.errorCode ||
            additionalInfo?.agentInterventionReason ||
            additionalInfo,
          rawErrorMessage: err.message,
          errorCodes: additionalInfo?.errorCodes,
        });
        const currentPath = window.location.pathname;
        currentPath !== getPathByName('ErrorPage') && // NOTE: Don't redirect if already on error page
          history.push({
            pathname: getPathByName('ErrorPage'),
            search: `?reason=${reason}${isBindFlow && '&flow=bind'}`,
          });
      }

      return shouldRedirectOnError ? Promise.reject(rawError) : undefined;
    },
    [setErrorPageState, history, handleModal, t, startOver],
  );

  return { handleApiError };
};

const useRequest = () => {
  const { handleApiError } = useHandleApiError();

  return useCallback(
    async function <R>(
      actionCreator: () => Promise<R>,
      cb?: (res: R) => void,
      shouldRedirectOnError = true,
      errorRanges: ErrorRangesType = {},
      errorCodes: ErrorCodesType = {},
    ): Promise<R | undefined> {
      try {
        const res = await actionCreator();
        cb && cb(res);
        return Promise.resolve(res);
      } catch (e) {
        return handleApiError({
          rawError: e,
          errorRanges,
          errorCodes,
          shouldRedirectOnError,
        });
      }
    },
    [handleApiError],
  );
};

function call<T extends (...args: any[]) => any>(
  fn: T,
  ...args: Parameters<T>
): ReturnType<T> {
  return fn(...args);
}

export type ActionReturnType<T extends keyof ActionCreators> = Awaited<
  ReturnType<ActionCreators[T]> | undefined
>;

export const useActionMakeRequest = <T extends keyof ActionCreators>(
  action: T,
  options?: {
    shouldRedirectOnError?: boolean;
    errorRanges?: ErrorRangesType;
    errorCodes?: ErrorCodesType;
  },
) => {
  const makeRequest = useRequest();
  const { actionCreators } = useActionCreators();

  const localAction = actionCreators[action];
  type actionType = typeof localAction;

  const actionCall = useCallback(
    (...args: Parameters<actionType>): ReturnType<actionType> =>
      makeRequest(
        () => call(localAction, ...args),
        undefined,
        options?.shouldRedirectOnError,
        options?.errorRanges,
        options?.errorCodes,
      ) as ReturnType<actionType>,
    [
      localAction,
      makeRequest,
      options?.shouldRedirectOnError,
      options?.errorRanges,
      options?.errorCodes,
    ],
  );

  return {
    makeRequest,
    action: localAction,
    actionCall,
  };
};

export const useActionRequest = <T extends keyof ActionCreators>(action: T) => {
  const { actionCreators } = useActionCreators();

  const localAction = actionCreators[action];
  type actionType = typeof localAction;

  const actionCall = useCallback(
    (...args: Parameters<actionType>): ReturnType<actionType> =>
      call(localAction, ...args),
    [localAction],
  );

  return {
    action: localAction,
    actionCall,
  };
};

export default useRequest;
