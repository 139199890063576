import { useMutation } from '@tanstack/react-query';
import { hasText } from '../../../helpers/functions';
import { setGtmDataLayer } from '../../../helpers/gtm';
import { useActionMakeRequest } from '../../makeRequest';
import { getSessionId } from '../utils';
import { eventKeys } from './eventKeys';

export type SendPageEventParam = {
  isNewRelicMonitor: boolean;
  pageName: string;
  properties?: Record<string, unknown>;
};

export const useSendPageEvent = () => {
  const { actionCall: action } = useActionMakeRequest('sendPageEvent', {
    shouldRedirectOnError: false,
  });

  return useMutation({
    mutationKey: eventKeys.mutations.sendPageEvent(),
    mutationFn: async ({
      isNewRelicMonitor,
      pageName,
      properties,
    }: SendPageEventParam) => {
      const sessionId = getSessionId();
      if (hasText(sessionId) && !isNewRelicMonitor) {
        try {
          setGtmDataLayer({
            event: `Viewed ${pageName} Page`,
            userId: sessionId,
            ...properties,
          });

          return await action({
            name: pageName,
            properties,
          });
        } catch (err: any) {
          console.error(`Event API error: ${err.message}`, err);
        }
      } else {
        console.info('Page event was not send, user id is missing.');
        if (isNewRelicMonitor) {
          console.info('NewRelicMonitor page call');
        }
      }

      return Promise.resolve();
    },
  });
};
