import React, { useContext } from 'react';
import { getCookie } from 'helpers/cookies';
import { omitBy } from 'lodash';
import { useActionCreators } from '../api/actions';
import useRequest from '../api/makeRequest';
import { ClubContext } from '../components/contexts/ClubContext';
import useUrlQuery from './useUrlQuery';

interface ClubConfigResponse {
  supportPhoneNo: string;
  officeLink: string;
}

export default function useClubInfo() {
  const { clubState, updateClubState } = useContext(ClubContext);
  const makeRequest = useRequest();
  const { getParam, getParamFromCookie } = useUrlQuery();
  const sessionId = getCookie('sessionId');
  const queryParam = getCookie('queryParam');
  // NOTE: Query parameters have precedence over cookies
  const potentialSourceCode =
    getParam('source') || getCookie('source');
  const clubcode =
    getParam('clubcode') ||
    getParamFromCookie(queryParam, 'clubcode', null) ||
    clubState.clubcode;
  const state = (
    getParam('state') ||
    getParamFromCookie(queryParam, 'state', null) ||
    clubState.state ||
    ''
  ).toUpperCase();
  const promocode =
    getParam('promocode') ||
    getParamFromCookie(queryParam, 'promocode', null) ||
    clubState.promocode;
  const dmlpid =
    getParam('DMLPID') ||
    getParamFromCookie(queryParam, 'DMLPID', null) ||
    clubState.dmlpid;
  const {
    actionCreators: { getClubInfo },
  } = useActionCreators();

  React.useEffect(() => {
    if (!clubcode) return;

    const acceptableSourceCodes = ['ie', 'club'];
    const shouldUseSourceParam =
      potentialSourceCode &&
      acceptableSourceCodes.includes(potentialSourceCode.toLowerCase());

    const sharedParams = omitBy<Record<string, any>>(
      {
        ...(shouldUseSourceParam && {
          source: potentialSourceCode?.toLowerCase(),
        }), //may remove case checks and conversions once API is updated
        state,
        promocode,
        dmlpid,
      },
      (v) => !v,
    );

    updateClubState({ clubcode, ...sharedParams });
    if (sessionId) {
      makeRequest(() =>
        getClubInfo({ clubCd: clubcode, ...sharedParams }, sessionId)
          .then((res: ClubConfigResponse) => {
            const { supportPhoneNo: phoneNumber, officeLink } = res;
            updateClubState({ phoneNumber, officeLink });
          })
          .catch(() => {
            //This catch Prevent default redirection to error screen
          }),
      );
    }
  }, [clubcode, sessionId]);

  return clubState;
}
